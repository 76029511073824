<section class="section-padding grid-news-hover grid-blog">
    <div class="container">

        <div class="row">
            <div class="col-md-9">
                <div class="row">
                    <div *ngFor="let post of posts | async">

                        <div class="col-xs-12 col-sm-6 col-md-6 blog-grid-item">

                            <app-post-list-item [post]="post"></app-post-list-item>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
