import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup  } from '@angular/forms';
import { Observable } from 'rxjs';

import { AngularFireStorage } from '@angular/fire/storage';


import { AuthService } from '../../core/auth.service';
import { PostService } from '../post.service';
import { Post } from '../post.model';
import { finalize } from 'rxjs/operators';


import { CategoryService } from '../../user/shared/category.service';
import { Category } from '../../user/shared/category.model';

declare function init_plugins();


@Component({
  selector: 'app-post-dashboard',
  templateUrl: './post-dashboard.component.html',
  styleUrls: ['./post-dashboard.component.css']
})
export class PostDashboardComponent implements OnInit {
  // here we can use the ViewChild from angular
  // to check if the input has anything inside hence the 'child'
  // inputField and resetMe is just a variable, you can name it as you like
  @ViewChild('resetMe', { static: true })
  inputField: any;


  //  categories: Observable<Category[]>;
//  categories: Category[] = [ ];

 categories: any [] = ['Videos', 'Musica', 'Noticias', 'Entretenimiento'];

  postForm: FormGroup;

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  imageURL: string;

  constructor(
     private postService: PostService,
     private storage: AngularFireStorage,
     private auth: AuthService ,
     private fb: FormBuilder,
     public categoryService: CategoryService

  ) { }

  ngOnInit(): void {
    this.createForm();
    init_plugins();
  }

  createForm() {
    this.postForm = this.fb.group({
      title: [''],
      category: [''],
      content: [''],
      fuente: [''],
      draft: false
    });
  }

async savePost() {
  const user = await this.auth.authState;
  // console.log(user);
  const formData: Post = {
    author: this.auth.authState.displayName || this.auth.authState.email,
    authorId: this.auth.currentUserId,
    title: this.postForm.get('title').value,
    category: this.postForm.get('category').value || null,
    image: this.imageURL || null,
    content: this.postForm.get('content').value,
    fuente: this.postForm.get('fuente').value,
    draft: this.postForm.get('draft').value || false,
    published: new Date(),
    trending: 0
  };
  if (!this.postForm.untouched) {
    this.postService.create(formData);
    this.postForm.reset();
    this.imageURL = '';
    // here we set the inputField back to empty
    this.inputField.nativeElement.value = '';
  }
}

async uploadPostImage(event) {
  const file = event.target.files[0];
  const path = `posts/${file.name}`;

  if (file.type.split('/')[0] !== 'image') {
    return alert('only image files');
  } else {
    const task = await this.storage.upload(path, file);
    const ref = this.storage.ref(path);
    this.downloadURL   = ref.getDownloadURL();
    // this.uploadPercent = (task.totalBytes / 100 );
    // console.log('Image Uploaded!');
    this.downloadURL.subscribe(url => this.imageURL = url);
  }
}


}
