import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';


import { Observable } from 'rxjs';
// add rxjs operator imports
import { map } from 'rxjs/operators';

import { Post } from './post.model';


@Injectable({
  providedIn: 'root'
})
export class PostService {
  postsCollection: AngularFirestoreCollection<Post>;
  postDoc: AngularFirestoreDocument<Post>;

  constructor( private afs: AngularFirestore ) {
    this.postsCollection = this.afs.collection('posts', ref =>
    ref.orderBy('trending', 'desc' ).limit(15));
  }

  getPosts(): Observable<Post[]> {
    // use pipe operator before mapping actions
    return this.postsCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Post;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

getPostData(id: string) {
 this.postDoc = this.afs.doc<Post>(`posts/${id}`);
 return this.postDoc.valueChanges();
}

getPost(id: string) {
  return this.afs.doc<Post>(`posts/${id}`);
 }

create(data: Post) {
    this.postsCollection.add(data);
  }

update(id: string, formData) {
  return this.getPost(id).update(formData);
}


delete(id: string) {
 return this.getPost(id).delete();
}

}
