import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Routes, RouterModule } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { NewsPaperComponent } from './newspaper/news-paper/news-paper.component';
import { PolicyPrivacyComponent } from './policy-privacy/policy-privacy.component';



import { LiveComponent } from './lives/live/live.component';
import { LiveListComponent } from './lives/live-list/live-list.component';
import { LiveDetailComponent } from './lives/live-detail/live-detail.component';
import { SharedModule } from '../user/shared/shared.module';
import { PagesComponent } from './pages.component';
import { NewsPaperListComponent } from './newspaper/news-paper-list/news-paper-list.component';
import { LiveAddComponent } from './lives/live-add/live-add.component';

const routes: Routes = [
  { path: 'live', component: LiveDetailComponent, data: { title: 'En Vivo'}},
  // { path: 'signup', component: SignupComponent, data: { title: 'Sign up'}},
  // { path: 'reset-password', component: ResetPasswordComponent, data: { title: 'Reset Password'}}
];

@NgModule({
  declarations: [
    PagesComponent,
    LiveComponent,
    LiveListComponent,
    LiveDetailComponent,
    AboutComponent,
    ContactComponent,
    NewsPaperComponent,
    NewsPaperListComponent,
    PolicyPrivacyComponent,
    LiveAddComponent

  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule

  ],
  exports: [
     LiveComponent,
    LiveListComponent,
    LiveDetailComponent,
    AboutComponent,
    ContactComponent,
    NewsPaperComponent,
    NewsPaperListComponent,
    PolicyPrivacyComponent

  ]
})
export class PagesModule { }
