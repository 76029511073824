import { Component, OnInit } from '@angular/core';


import { Observable } from 'rxjs';

import { CategoryService } from '../category.service';
import { Category } from '../category.model';


@Component({
  selector: 'app-category-widget',
  templateUrl: './category-widget.component.html',
  styles: [
  ]
})
export class CategoryWidgetComponent implements OnInit {
  categories: Observable<Category[]>;

  constructor( private categoryService: CategoryService ) { }

  ngOnInit(): void {
    this.categories = this.categoryService.getCategories();
  }

}
