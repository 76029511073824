import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import * as firebase from 'firebase';


import { Md5 } from 'ts-md5/dist/md5';

import { User } from '../user/user.model';


// interface User {
//   uid: string;
//   email: string;
//   photoURL?: string;
//   displayName?: string;

// }

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<User>;
  authState: firebase.User;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router

    ) {
      this.user = this.afAuth.authState.pipe(
        switchMap(user => {
          this.authState = user;
          // console.log('Firebase User Object: ', this.authState);
          if (user) {
            // console.log('App User: ', this.user);
            return this.afs.doc<any>(`users/${user.uid}`).valueChanges();
          } else {
            return of(null);
          }
        })
      //  this.afAuth.authState.subscribe( data => { this.authState = data})
      );
    }

    getUser() {
      return this.user.pipe(first()).toPromise();
    }

    get authenticated(): boolean {
      return this.authState !== null;
    }

    get currentUserId(): string  {
      return this.authenticated ? this.authState.uid : null;
    }


    emailSignIn(email: string, password: string) {
      return this.afAuth
      // return this.afAuth.auth
        .signInWithEmailAndPassword(email, password)
        .then(() => this.router.navigate(['/me']))
        .catch(error => console.log(error.message));
    }


    emailSignUp(email: string, password: string) {
      return this.afAuth
        .createUserWithEmailAndPassword(email, password)
        .then(data => this.updateUserData(data.user))
        .then(() => console.log('Welcome, your account has been created!'))
        .then(async () => {
          (await this.afAuth.currentUser)
            .sendEmailVerification()
            .then(() => console.log('We sent you an email verification'))
            .catch(error => console.log(error.message));
              this.router.navigate(['/post']);
        })
        .catch(error => console.log(error.message));
    }

    resetPassword(email: string ) {
      return firebase.auth().sendPasswordResetEmail(email)
      .then(() => console.log(' We ve sent you a password reset link') )
      .catch(error => console.log(error.message));
    }

    signOut() {
      return this.afAuth.signOut().then(() => {
        this.router.navigate(['/']);
      });
    }

    googleLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      return this.socialLogin(provider);
    }


  private socialLogin(provider) {
    return this.afAuth
      .signInWithPopup(provider)
      .then(credential => {
        this.updateUserData(credential.user);
      })
      .catch(error => console.log(error.message));
  }

  private updateUserData(user) {
  const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
  const data: User = {
    uid: user.uid,
    email: user.email || null,
    displayName: user.displayName,
    photoURL: user.photoURL ||
    'https://www.gravatar.com/avatar/' + Md5.hashStr(user.uid) + '?d=mp',
    role: 'usuario'
  };
  return userRef.set(data, {merge: true });
}

  }
