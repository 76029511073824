import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoPageService } from './services/info-page.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  // title = 'lagrasadigital';

constructor( public infoPageSerice: InfoPageService, private matDialog: MatDialog ) {

  // console.log('InfoPage service');


}

}
