import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { Newspaper } from './newspaper.model';

@Injectable({
  providedIn: 'root'
})
export class NewspaperService {
  newspapersCollection: AngularFirestoreCollection<Newspaper>;
  newspaperDoc: AngularFirestoreDocument<Newspaper>;

  constructor( private afs: AngularFirestore ) {
    this.newspapersCollection = afs.collection('newspapers', ref =>
    ref.orderBy('desc').limit(40));
  }

  getNewspaper() {
    this.newspapersCollection = this.afs.collection('newspapers');
    return this.newspapersCollection.valueChanges();
  }

create(data: Newspaper){
  this.newspapersCollection.add(data);
}

}
