<div class="row">

    <div class="col-md-8 col-md-offset-2">

        <h1>Crear Post</h1>

        <section>
            <!-- add #resetMe // we will use it to reference this input field -->
            <input #resetMe type="file" name="file" id="file" (change)="uploadPostImage($event)">
            <!-- add the "|| !imageURL" to the hidden condition here too as we can't easily reset an observable -->
            <div [hidden]="!uploadPercent ">
                <!-- <mat-progress-bar mode="determinate" value="{{ uploadPercent | async }}"></mat-progress-bar> -->
            </div>
            <a [href]="downloadURL | async" [hidden]="!downloadURL || !imageURL"> click this link
        {{ downloadURL | async }}</a>

            <div class="materialboxed" width="650" [hidden]="!imageURL">
                <img [src]="imageURL || '//:0' ">
            </div>

        </section>

        <form [formGroup]="postForm" (ngSubmit)="savePost()">
            <!-- Upload Buttom -->



            <div class="form-group">
                <label for="title">Titulo <span class="require">*</span></label>
                <input type="text" class="form-control" formControlName="title" />
            </div>

            <div class="form-group">
                <!-- <label for="category">categoria <span class="require">*</span></label>
                <input type="text" class="form-control" formControlName="category" /> -->


                <label for="category">Categoria</label>
                <!-- <select formControlName="category">
                  <option *ngFor="let category of categories" [ngValue]="category.id">
                    {{ category.categoryName }}
                  </option>
                </select> -->

                <!--
                <select class="form-control" name="category" formControlName="category">

                <option value=""></option>

                <option *ngFor="let category of categories" [value]="category">
                  {{ categories}}
                </option>

                </select>

 -->
                <div class="form-group">
                    <!-- <label for="power">Catalog</label> -->
                    <select class="form-control" id="category" formControlName="category" name="category">
            <option *ngFor="let category of categories" [ngValue]="category">{{category }}

            </option>
          </select>

                </div>

            </div>
            <div class="form-group">
                <label for="content">Contenido</label>
                <textarea rows="7" class="form-control" formControlName="content"></textarea>
            </div>
            <div class="form-group">
                <label for="fuente">Fuente <span class="require">*</span></label>
                <input type="text" class="form-control" formControlName="fuente" />
            </div>

            <div class="form-group">
                <p><span class="require">*</span> - required fields</p>
            </div>



            <!-- Switch -->
            <div class="switch">
                <label> Publicar ?<input type="checkbox" formControlName="draft">
          <span class="lever"></span> </label>
            </div>


            <div class="form-group">
                <button type="submit" class="btn btn-primary">
          Guardar
        </button>
                <!--    <button class="btn btn-default">
                Cancel
            </button> -->
            </div>

        </form>
    </div>

</div>
