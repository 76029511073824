import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AngularFireStorage } from '@angular/fire/storage';

import { FormBuilder, FormControl, FormGroup, Validators  } from '@angular/forms';
import { CategoryService } from '../category.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Category } from '../category.model';



@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styles: [
  ]
})
export class CategoryComponent implements OnInit {

  categoryForm: FormGroup;

  constructor( private categoryService: CategoryService,
               private storage: AngularFirestore,
               private fb: FormBuilder ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.categoryForm = this.fb.group({
      categoryName : ['', [Validators.required, Validators.minLength(3)]]
    });
  }

  saveCategory() {
    const formData: Category = {

      categoryName: this.categoryForm.get('categoryName').value
    };
    if (!this.categoryForm.untouched) {
      this.categoryService.create(formData);
      this.categoryForm.reset();
    }
  }

}
