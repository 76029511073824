<!-- <mat-form-field>
    <input matInput placeholder="Send message" [(ngModel)]="message" (keydown)="handleSubmit($event)">
    <mat-icon matSuffix (click)="send()">send</mat-icon>
</mat-form-field>
 -->

<!--

<div class="container">
    <div class="col-md-10">
        <div class="bottom">

            <textarea class="form-control" [(ngModel)]="message" (keydown)="handleSubmit($event)" placeholder="Empieza a escribir para enviar tu mensaje..." rows="1"></textarea>
            <button class="btn waves-effect waves-light" type="submit" (click)="send()">Enviar
                                  <i (click)="send()" class="material-icons right" >send</i>
                                </button>

            <div class="add-smiles">
                <span title="add icon" class="em em-blush"></span>

            </div>
        </div>
    </div>
</div> -->



<!-- Chat footer <-->
<div class="chat-footer">
    <!-- <form onsubmit="enter_chat();" action="javascript:void(0);" class="chat-input"> -->
    <i class="material-icons mr-2">face</i>

    <input type="text" placeholder="escriba su mensaje..." class="message mb-0">
    <a class="btn waves-effect waves-light send" (click)="send()">enviar</a>
    <!-- </form> -->
</div>
<!--/ Chat footer -->
