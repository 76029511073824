<!-- blog section start -->
<section class="blog-section section-padding">
    <div *ngIf="post" class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="posts-content single-post">

                    <article class="post-wrapper">

                        <header class="entry-header-wrapper clearfix">

                            <div class="author-thumb waves-effect waves-light">
                                <a href="#"><img [src]="post.image" alt=""></a>
                            </div>

                            <div class="entry-header">
                                <h2 class="entry-title">{{post.title}}</h2>

                                <div class="entry-meta">
                                    <ul class="list-inline">
                                        <li>
                                            <i class="fa fa-user"></i><a href="#">{{post.author}}</a>
                                        </li>

                                        <li>
                                            <i class="fa fa-clock-o"></i><a href="#">{{post.published | fromNow}}</a>
                                        </li>

                                        <li>
                                            <i class="fa fa-heart-o"></i><a href="#"><span>{{post.trending}}</span></a>
                                        </li>

                                        <!-- <li>
                                            <i class="fa fa-comment-o"></i><a href="#">3</a>
                                        </li> -->
                                    </ul>
                                </div>
                                <!-- .entry-meta -->
                            </div>

                        </header>
                        <!-- /.entry-header-wrapper -->

                        <div class="thumb-wrapper">
                            <img [src]="post.image" class="img-responsive" alt="">
                        </div>
                        <!-- .post-thumb -->


                        <div class="entry-content">
                            <p>{{post.content}}
                            </p>
                            <div class="b-info">

                                <a target="_blank" href="{{post.fuente}}" class="btn waves-effect waves-light"><i class="fa fa-globe"></i> Fuente</a>

                            </div>
                            <br>
                        </div>
                        <!-- .entry-content -->


                        <footer class="entry-footer">
                            <div class="post-tags">
                                <span class="tags-links">
                              <i class="fa fa-tags"></i><a href="#">{{post.category}}</a>
                            </span>
                            </div>
                            <!-- .post-tags -->

                            <!-- <ul class="list-inline right share-post">
                                <li><a href="#"><i class="fa fa-facebook"></i> <span>Share</span></a>
                                </li>
                                <li><a href="#"><i class="fa fa-twitter"></i> <span>Tweet</span></a>
                                </li>
                                <li><a href="#"><i class="fa fa-google-plus"></i> <span>Plus</span></a>
                                </li>
                            </ul> -->
                        </footer>

                    </article>
                    <!-- /.post-wrapper -->

                    <nav class="single-post-navigation" role="navigation">
                        <!-- <div class="row"> -->
                        <!-- Previous Post -->
                        <!-- <div class="col-xs-6">
                                <div class="previous-post-link">
                                    <a class="waves-effect waves-light" href="#"><i class="fa fa-long-arrow-left"></i>Read Previous Post</a>
                                </div>
                            </div> -->

                        <!-- Next Post -->
                        <!-- <div class="col-xs-6">
                                <div class="next-post-link">
                                    <a class="waves-effect waves-light" href="#">Read Next Post<i class="fa fa-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div> -->
                        <!-- .row -->
                    </nav>

                    <div class="comments-wrapper">
                        <div class="comments-tab">

                            <!-- Nav tabs -->
                            <!-- <ul class="nav nav-tabs nav-justified" role="tablist">
                                <li role="presentation" class="active"><a href="#default-comment" class="waves-effect waves-light" role="tab" data-toggle="tab">Leave a comment now</a></li>
                            </ul> -->

                            <!-- Tab panes -->
                            <!-- <div class="panel-body">
                                <div class="tab-content">
                                    <div role="tabpanel" class="tab-pane fade in active" id="default-comment">
                                        <div class="comment-respond">

                                            <form action="#" method="post" id="commentform" novalidate="" role="form">
                                                <div class="form-group">
                                                    <div class="row">

                                                        <div class="col-sm-4 comment-form-author">
                                                            <input class="form-control" id="author" placeholder="Your Name" name="author" type="text" value="" aria-required="true">
                                                        </div>

                                                        <div class="col-sm-4 comment-form-email">
                                                            <input id="email" class="form-control" name="email" placeholder="Email Address" type="email" value="" aria-required="true">
                                                        </div>

                                                        <div class="col-sm-4 comment-form-subject">
                                                            <input class="form-control" placeholder="Subject" id="subject" name="subject" type="text" value="">
                                                        </div>

                                                    </div>

                                                </div>

                                                <div class="form-group comment-form-comment">
                                                    <textarea class="form-control" id="comment" name="comment" placeholder="Comment" rows="8" aria-required="true"></textarea>
                                                </div>

                                                <div class="form-submit">
                                                    <button class="btn btn-lg pink waves-effect waves-light" name="submit" type="submit" id="submit" value="">Submit</button>
                                                </div>
                                            </form>

                                        </div>

                                    </div>
                                    <div role="tabpanel" class="tab-pane fade" id="facebook-comment">
                                        <p>Duis senectus mus condimentum nunc ac habitasse duis consectetur a arcu a accumsan cras et metus ultricies justo cum a bibendum. <a href="#">Egestas vestibulum blandit sem vestibulum curabitur</a> a vel aliquet
                                            gravida ullamcorper amet dictumst vestibulum a elementum proin id volutpat magna parturient. Id ac dui libero a ullamcorper euismod himenaeos a nam condimentum a adipiscing dapibus lobortis iaculis morbi.</p>

                                        <p>Himenaeos a vestibulum morbi. <a href="#">Ullamcorper cras scelerisque</a> taciti lorem metus feugiat est lacinia facilisis id nam leo condimentum praesent id diam. Vestibulum amet porta odio elementum convallis
                                            parturient tempor tortor tempus a mi ad parturient ad nulla mus amet in penatibus nascetur at vulputate euismod a est tristique scelerisque. Aliquet facilisis nisl vel vestibulum dignissim gravida ullamcorper
                                            hac quisque ad at nisl egestas adipiscing vel blandit.</p>
                                    </div>
                                </div> -->
                            <!-- /.tab-content -->
                            <!-- </div> -->
                            <!-- /.panel-body -->

                        </div>
                        <!-- /.comments-tab -->

                    </div>
                    <!-- /.comments-wrapper -->

                </div>
                <!-- /.posts-content -->
            </div>
            <!-- /.col-md-8 -->

            <div class="col-md-4">
                <div class="tt-sidebar-wrapper" role="complementary">
                    <div class="widget widget_search">
                        <form role="search" method="get" class="search-form">
                            <input type="text" class="form-control" value="" name="s" id="s" placeholder="Write any keywords">
                            <button type="submit"><i class="fa fa-search"></i></button>
                        </form>
                    </div>
                    <!-- /.widget_search -->


                    <div class="widget widget_tt_author_widget">

                        <app-user-widget></app-user-widget>
                        <!-- /author-info-wrapper -->
                    </div>
                    <!-- /.widget_tt_author_widget -->


                    <div class="widget widget_tt_popular_post">
                        <div class="tt-popular-post border-bottom-tab">
                            <!-- Nav tabs -->
                            <ul class="nav nav-tabs">
                                <li class="active">
                                    <a href="#tt-popular-post-tab1" data-toggle="tab" aria-expanded="true">Latest</a>
                                </li>
                                <li class="">
                                    <a href="#tt-popular-post-tab2" data-toggle="tab" aria-expanded="false">Popular</a>
                                </li>
                            </ul>

                            <!-- Tab panes -->
                            <div class="tab-content">
                                <!-- latest post tab -->
                                <div id="tt-popular-post-tab1" class="tab-pane fade active in">

                                    <div class="media">
                                        <a class="media-left" href="#">
                                            <img src="assets/img/blog/recent-thumb-3.jpg" alt="">
                                        </a>

                                        <div class="media-body">
                                            <h4><a href="#">At the end of the day, or at the start of the day?</a></h4>
                                        </div>
                                        <!-- /.media-body -->
                                    </div>
                                    <!-- /.media -->

                                    <div class="media">
                                        <a class="media-left" href="#">
                                            <img src="assets/img/blog/recent-thumb-4.jpg" alt="">
                                        </a>

                                        <div class="media-body">
                                            <h4><a href="#">What never to tweet about</a></h4>
                                        </div>
                                        <!-- /.media-body -->
                                    </div>
                                    <!-- /.media -->

                                    <div class="media">
                                        <a class="media-left" href="#">
                                            <img src="assets/img/blog/recent-thumb-5.jpg" alt="">
                                        </a>

                                        <div class="media-body">
                                            <h4><a href="#">Men have become the tools of their tools.</a></h4>
                                        </div>
                                        <!-- /.media-body -->
                                    </div>
                                    <!-- /.media -->

                                    <div class="media">
                                        <a class="media-left" href="#">
                                            <img src="assets/img/blog/recent-thumb-1.jpg" alt="">
                                        </a>

                                        <div class="media-body">
                                            <h4><a href="#">Master the psychology of web design</a></h4>
                                        </div>
                                        <!-- /.media-body -->
                                    </div>
                                    <!-- /.media -->

                                    <div class="media">
                                        <a class="media-left" href="#">
                                            <img src="assets/img/blog/recent-thumb-2.jpg" alt="">
                                        </a>

                                        <div class="media-body">
                                            <h4><a href="#">CTC to showcase technology solutions at Sea A......</a></h4>
                                        </div>
                                        <!-- /.media-body -->
                                    </div>
                                    <!-- /.media -->

                                </div>

                                <!-- popular post tab-->
                                <div id="tt-popular-post-tab2" class="tab-pane fade">

                                    <div class="media">
                                        <a class="media-left" href="#">
                                            <img src="assets/img/blog/recent-thumb-1.jpg" alt="">
                                        </a>

                                        <div class="media-body">
                                            <h4><a href="#">Master the psychology of web design</a></h4>
                                        </div>
                                        <!-- /.media-body -->
                                    </div>
                                    <!-- /.media -->

                                    <div class="media">
                                        <a class="media-left" href="#">
                                            <img src="assets/img/blog/recent-thumb-2.jpg" alt="">
                                        </a>

                                        <div class="media-body">
                                            <h4><a href="#">CTC to showcase technology solutions at Sea A......</a></h4>
                                        </div>
                                        <!-- /.media-body -->
                                    </div>
                                    <!-- /.media -->

                                    <div class="media">
                                        <a class="media-left" href="#">
                                            <img src="assets/img/blog/recent-thumb-3.jpg" alt="">
                                        </a>

                                        <div class="media-body">
                                            <h4><a href="#">At the end of the day, or at the start of the day?</a></h4>
                                        </div>
                                        <!-- /.media-body -->
                                    </div>
                                    <!-- /.media -->

                                    <div class="media">
                                        <a class="media-left" href="#">
                                            <img src="assets/img/blog/recent-thumb-4.jpg" alt="">
                                        </a>

                                        <div class="media-body">
                                            <h4><a href="#">What never to tweet about</a></h4>
                                        </div>
                                        <!-- /.media-body -->
                                    </div>
                                    <!-- /.media -->

                                    <div class="media">
                                        <a class="media-left" href="#">
                                            <img src="assets/img/blog/recent-thumb-5.jpg" alt="">
                                        </a>

                                        <div class="media-body">
                                            <h4><a href="#">Men have become the tools of their tools.</a></h4>
                                        </div>
                                        <!-- /.media-body -->
                                    </div>
                                    <!-- /.media -->

                                </div>
                            </div>
                            <!-- /.tab-content -->
                        </div>
                        <!-- /.tt-popular-post -->
                    </div>
                    <!-- /.widget_tt_popular_post -->


                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a href="#">Technology</a></li>
                            <li><a href="#">Media</a></li>
                            <li><a href="#">Video</a></li>
                            <li><a href="#">Audio</a></li>
                            <li><a href="#">Design</a></li>
                            <li><a href="#">Material</a></li>
                        </ul>
                    </div>
                    <!-- /.widget_categories -->


                </div>
                <!-- /.tt-sidebar-wrapper -->
            </div>
            <!-- /.col-md-4 -->

        </div>
        <!-- /.row -->
    </div>
    <!-- /.container -->
</section>
<!-- blog section end -->
