import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AngularFireStorage } from '@angular/fire/storage';

import { FormBuilder, FormControl, FormGroup, Validators  } from '@angular/forms';


import { NewspaperService } from '../newspaper.service';
import { Newspaper } from '../newspaper.model';

@Component({
  selector: 'app-news-paper',
  templateUrl: './news-paper.component.html',
  styleUrls: ['./news-paper.component.css']
})
export class NewsPaperComponent implements OnInit {
  inputField: any;

  newsForm: FormGroup;
  downloadURL: Observable<string>;
  logoURL: string;



  constructor( private newsPaperService: NewspaperService,
               private storage: AngularFireStorage,
               private fb: FormBuilder,
                ) { }

  ngOnInit(): void {
    this.createForm();
  }


  createForm() {
    // const urlRegex = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    this.newsForm = this.fb.group({
      name : ['', [Validators.required, Validators.minLength(3)]],
      url: ['', [Validators.required, Validators.pattern(urlRegex)]]
    });
  }

  saveNewsPaper(){
    const formData: Newspaper = {
      name: this.newsForm.get('name').value,
      url: this.newsForm.get('url').value,
      logo: this.logoURL || null

    };
    if (!this.newsForm.untouched) {
      this.newsPaperService.create(formData);
      this.newsForm.reset();
      this.logoURL = '';
      // here we set the inputField back to empty
      // this.inputField.nativeElement.value = '';
    }
  }

  async uploadNewsImage(event) {
    const file = event.target.files[0];
    const path = `newspapers/${file.name}`;

    if (file.type.split('/')[0] !== 'image'){
      return alert ('Solo archivos de Imagenes');
    } else {
      const task = await this.storage.upload(path, file);
      const ref = this.storage.ref(path);
      this.downloadURL = ref.getDownloadURL();
      this.downloadURL.subscribe(url => this.logoURL = url );
    }
  }

}



