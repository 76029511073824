
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';


import { AuthService } from '../../core/auth.service';
import { SharedModule } from '../../user/shared/shared.module';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
signUpForm: FormGroup;
hide = true;

constructor(
  public fb: FormBuilder,
  private auth: AuthService,
  private router: Router

) { this.signUpForm = this.fb.group({
  email: ['', [Validators.email, Validators.required]],
  password: ['',
        [
          Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
          Validators.minLength(6),
          Validators.maxLength(25),


        ]
]
});
}

ngOnInit(): void {  }

get email() {
  return this.signUpForm.get('email');
}

get password() {
  return this.signUpForm.get('password');
}

  async signUp() {

  const user = await this.auth.emailSignUp(this.email.value, this.password.value);
  if (this.signUpForm.valid) {

    // this.router.navigate(['/post']);
    this.router.navigate(['/me']);
  }

}

}
