<section class="section-padding">
    <div class="container">

        <div class="text-center mb-80">
            <h2 class="section-title text-uppercase">Quienes somos</h2>
            <p class="section-sub"> Gente apacionados por la musica, que nos complace llevarles alegria a sus corazones.</p>
        </div>


        <div class="row">
            <div class="col-md-4">
                <div class="featured-item feature-icon icon-hover icon-hover-blue icon-circle icon-outline clearfix">
                    <div class="icon">
                        <i class="material-icons colored brand-icon">&#xE323;</i>
                    </div>
                    <div class="desc">
                        <h2>Somos Innovadores</h2>
                        <p>Hay un límite para las pasiones de los hombres cuando actúan desde los sentimientos; pero ninguno cuando están bajo la influencia de la imaginación.</p>
                    </div>
                </div>
                <!-- /.featured-item -->
            </div>
            <!-- /.col-md-4 -->

            <div class="col-md-4">
                <div class="featured-item feature-icon icon-hover icon-hover-blue icon-circle icon-outline clearfix">
                    <div class="icon">
                        <i class="material-icons colored brand-icon">&#xE412;</i>
                    </div>
                    <div class="desc">
                        <h2>Creativos</h2>
                        <p>Las ideas son como los conejos. Obtienes un par y aprendes cómo manejarlos, y muy pronto tienes una docena.</p>
                    </div>
                </div>
                <!-- /.featured-item -->
            </div>
            <!-- /.col-md-4 -->

            <div class="col-md-4">
                <div class="featured-item feature-icon icon-hover icon-hover-blue icon-circle icon-outline clearfix">
                    <div class="icon">
                        <i class="material-icons colored brand-icon">&#xE02C;</i>
                    </div>
                    <div class="desc">
                        <h2>Productores Apacionados</h2>
                        <p>La calidad es más importante que la cantidad. Un jonrón es mucho mejor que dos dobles</p>
                    </div>
                </div>
                <!-- /.featured-item -->
            </div>
            <!-- /.col-md-4 -->
        </div>
    </div>
</section>


<section class="section-padding banner-10 bg-fixed parallax-bg overlay light-9" data-stellar-background-ratio="0.5">
    <div class="container">

        <div class="text-center mb-80">
            <h2 class="section-title text-uppercase">Nuestro Grandioso Equipo</h2>
            <p class="section-sub">El trabajo en equipo es la capacidad de trabajar juntos hacia una visión común. La capacidad de dirigir los logros individuales hacia objetivos de la organización. Es el combustible que permite a las personas comunes obtener resultados poco
                comunes.
            </p>
        </div>

        <div class="row">
            <div *ngFor="let staff of inforService.staff" class="col-md-3 col-sm-6">
                <div class="team-wrapper text-center">
                    <div class="team-img">
                        <a href="#"><img src="{{staff.url}}" class="img-responsive" alt="Image"></a>
                    </div>
                    <!-- /.team-img -->

                    <div class="team-title">
                        <h3><a href="#">{{staff.nombre}}</a></h3>
                        <span>{{staff.subtitulo}}</span>
                    </div>
                    <!-- /.team-title -->

                    <ul class="team-social-links list-inline">
                        <li><a target="_blank" href="{{staff.facebook}}"><i class="fa fa-facebook"></i></a></li>
                        <li><a target="_blank" href="{{staff.twitter}}"><i class="fa fa-twitter"></i></a></li>
                        <li><a target="_blank" href="{{staff.instagram}}"><i class="fa fa-instagram"></i></a></li>
                        <li><a target="_blank" href="{{staff.youtube}}"><i class="fa fa-youtube-square"></i></a></li>
                        <li><a target="_blank" href="{{staff.soundcloud}}"><i class="fa fa-soundcloud"></i></a></li>
                        <li><a target="_blank" href="{{staff.twitch}}"><i class="fa fa-twitch"></i></a></li>
                    </ul>

                </div>
                <!-- /.team-wrapper -->
            </div>
            <!-- /.col-md-3 -->

        </div>
        <!-- /.row -->

    </div>
    <!-- /.container -->
</section>


<section class="section-padding">
    <div class="container">

        <div class="text-center mb-80">
            <h2 class="section-title text-uppercase">Nuestros Patrocinadores</h2>
            <!-- <p class="section-sub">Quisque non erat mi. Etiam congue et augue sed tempus. Aenean sed ipsum luctus, scelerisque ipsum nec, iaculis justo. Sed at vestibulum purus, sit amet viverra diam nulla ac nisi rhoncus.</p> -->
        </div>

        <div class="row">
            <div class="col-md-12">
                <div id="client-testimonial" class="carousel slide carousel-testimonial text-center gray-bg" data-ride="carousel">

                    <!-- Wrapper for slides -->
                    <div class="carousel-inner" role="listbox">
                        <div class="item active">
                            <div class="avatar">
                                <img src="assets/img/quote-dark.png" alt="Customer Thumb">
                            </div>
                            <div class="content">
                                <p>Quam adipiscing vestibulum feugiat lacus leo a eget leo convallis sagittis nisi varius eros a imperdiet.Dui elementum ut a vestibulum eu fames hendrerit class conubia consequat curae fames hendrerit class conubia consequat.</p>

                                <div class="testimonial-meta brand-color">
                                    Jhon Doe,
                                    <span>User Interface Designer</span>
                                </div>
                            </div>
                        </div>

                        <div class="item">
                            <div class="avatar">
                                <img src="assets/img/quote-dark.png" alt="Customer Thumb">
                            </div>
                            <div class="content">
                                <p>Quam adipiscing vestibulum feugiat lacus leo a eget leo convallis sagittis nisi varius eros a imperdiet.Dui elementum ut a vestibulum eu fames hendrerit class conubia consequat curae fames hendrerit class conubia consequat.</p>

                                <div class="testimonial-meta brand-color">
                                    Elita Chow,
                                    <span>User Interface Designer</span>
                                </div>
                            </div>
                        </div>

                        <div class="item">
                            <div class="avatar">
                                <img src="assets/img/quote-dark.png" alt="Customer Thumb">
                            </div>
                            <div class="content">
                                <p>Quam adipiscing vestibulum feugiat lacus leo a eget leo convallis sagittis nisi varius eros a imperdiet.Dui elementum ut a vestibulum eu fames hendrerit class conubia consequat curae fames hendrerit class conubia consequat.</p>

                                <div class="testimonial-meta brand-color">
                                    Tomas Hody,
                                    <span>User Interface Designer</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Controls -->
                    <a class="left carousel-control" href="#client-testimonial" role="button" data-slide="prev">
                        <span class="material-icons" aria-hidden="true">&#xE5C4;</span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="right carousel-control" href="#client-testimonial" role="button" data-slide="next">
                        <span class="material-icons" aria-hidden="true">&#xE5C8;</span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
            <!-- /.col-md-12 -->
        </div>

    </div>
</section>


<section class="padding-bottom-100">
    <div class="container">

        <div class="clients-grid grid-gutter">
            <div class="row">

                <div class="col-md-3 col-sm-6">
                    <div class="border-box">
                        <a href="#">
                            <img src="assets/img/client-logo/disponibleAzul.png" alt="clients">
                        </a>
                    </div>
                    <!-- /.border-box -->
                </div>
                <!-- /.col-md-3 -->

                <div class="col-md-3 col-sm-6">
                    <div class="border-box">
                        <a href="#">
                            <img src="assets/img/client-logo/disponibleRosa.png" alt="clients">
                        </a>
                    </div>
                    <!-- /.border-box -->
                </div>
                <!-- /.col-md-3 -->

                <div class="col-md-3 col-sm-6">
                    <div class="border-box">
                        <a href="#">
                            <img src="assets/img/client-logo/disponibleAzul.png" alt="clients">
                        </a>
                    </div>
                    <!-- /.border-box -->
                </div>
                <!-- /.col-md-3 -->

                <div class="col-md-3 col-sm-6">
                    <div class="border-box">
                        <a href="#">
                            <img src="assets/img/client-logo/disponibleRosa.png" alt="clients">
                        </a>
                    </div>
                    <!-- /.border-box -->
                </div>
                <!-- /.col-md-3 -->

                <div class="col-md-3 col-sm-6">
                    <div class="border-box">
                        <a href="#">
                            <img src="assets/img/client-logo/disponibleAzul.png" alt="clients">
                        </a>
                    </div>
                    <!-- /.border-box -->
                </div>
                <!-- /.col-md-3 -->

                <div class="col-md-3 col-sm-6">
                    <div class="border-box">
                        <a href="#">
                            <img src="assets/img/client-logo/disponibleRosa.png" alt="clients">
                        </a>
                    </div>
                    <!-- /.border-box -->
                </div>
                <!-- /.col-md-3 -->

                <div class="col-md-3 col-sm-6">
                    <div class="border-box">
                        <a href="#">
                            <img src="assets/img/client-logo/disponibleAzul.png" alt="clients">
                        </a>
                    </div>
                    <!-- /.border-box -->
                </div>
                <!-- /.col-md-3 -->

                <div class="col-md-3 col-sm-6">
                    <div class="border-box">
                        <a href="#">
                            <img src="assets/img/client-logo/disponibleRosa.png" alt="clients">
                        </a>
                    </div>
                    <!-- /.border-box -->
                </div>
                <!-- /.col-md-3 -->

            </div>
            <!-- /.row -->
        </div>
        <!-- /.clients-grid -->

    </div>
    <!-- /.container -->
</section>
