<div class="widget widget_tt_popular_post" *ngFor="let category of categories |async">
    <div routerLink={{category.id}} class="tt-popular-post border-bottom-tab">

        <!-- Tab panes -->
        <div class="tab-content">
            <!-- latest post tab -->
            <div id="tt-popular-post-tab1" class="tab-pane fade active in">

                <div class="media">
                    <!-- <a class="media-left">
                      <img [src]="user.photoURL ||'//:0'" alt="">
                  </a> -->

                    <div class="media-body">
                        <h2 class="text-extrabold text-uppercase">{{ category.categoryName }}</h2>

                    </div>
                    <!-- /.media-body -->
                </div>
                <!-- /.media -->
                <!-- popular post tab-->

            </div>
            <!-- /.tab-content -->
        </div>
        <!-- /.tt-popular-post -->
    </div>
    <!-- /.widget_tt_popular_post -->
