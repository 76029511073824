<!-- blog section start -->
<section class="blog-section section-padding">
    <div class="container">
        <div class="row">


            <div *ngFor="let live of lives | async" class="col-md-4">
                <div class="posts-content default-blog">

                    <article class="post-wrapper">

                        <div class="thumb-wrapper">
                            <img src="{{live.liveImgURL}}" class="img-responsive" alt="{{live.name}}">

                            <div class="entry-header">


                                <h2 class="entry-title"><a href="{{live.liveUrl}}" target="_blank">{{live.name}}</a></h2>

                            </div>
                            <!-- .entry-header -->



                        </div>
                        <!-- .post-thumb -->


                        <div class="entry-content">
                            <p>{{live.desc}}</p>
                        </div>
                        <!-- .entry-content -->

                    </article>
                    <!-- /.post-wrapper -->

                </div>
                <!-- /.posts-content -->
            </div>
            <!-- /.col-md-8 -->

        </div>
        <!-- /.row -->
    </div>
    <!-- /.container -->
</section>
<!-- blog section end -->