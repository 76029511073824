import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { Category } from './category.model';


@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  categoryCollection: AngularFirestoreCollection<Category>;

  constructor( private afs: AngularFirestore) {
    this.categoryCollection = this.afs.collection('categories', ref =>
    ref.orderBy('categoryName', 'desc'));
   }

   getCategories() {
  this.categoryCollection = this.afs.collection('categories');
  return this.categoryCollection.valueChanges();
}

create(data: Category) {
  this.categoryCollection.add(data);
}

}
