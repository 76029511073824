import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { LiveService } from '../live.service';
import { Live } from '../live.model';



@Component({
  selector: 'app-live-list',
  templateUrl: './live-list.component.html',
  styleUrls: ['./live-list.component.css']
})
export class LiveListComponent implements OnInit {
lives: Observable<Live[]>;


  constructor( private liveService: LiveService) { }

  ngOnInit(): void {
    this.lives = this.liveService.getLive();
  }

}
