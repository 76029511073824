import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Live } from './live.model';

@Injectable({
  providedIn: 'root'
})
export class LiveService {
  livesCollection: AngularFirestoreCollection<Live>;
  liveDoc: AngularFirestoreDocument<Live>;

  constructor( private afs: AngularFirestore ) {
    this.livesCollection = afs.collection('lives', ref =>
    ref.orderBy('name'));
  }

getLive() {
  this.livesCollection = this.afs.collection('lives');
  return this.livesCollection.valueChanges();
}
create(data: Live) {
  this.livesCollection.add(data);
}

}
