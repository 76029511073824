import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CategoryComponent } from './category/category.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryWidgetComponent } from './category-widget/category-widget.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { FromNowPipe } from './from-now.pipe';
import { AmTimeAgoPipe } from './am-time-ago.pipe';
import { MomentModule } from 'ngx-moment';
import { UserWidgetComponent } from './user-widget/user-widget.component';
import { UploadComponent } from './upload/upload.component';
import { UploadService } from './upload/upload.service';
import { NoPageComponent } from './no-page/no-page.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DialogBodyComponent } from './dialog-body/dialog-body.component';
import { MaterialModule } from '../../material.module';




@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    CategoryComponent,
    CategoryListComponent,
    CategoryWidgetComponent,
    UnderConstructionComponent,
    FromNowPipe,
    AmTimeAgoPipe,
    UserWidgetComponent,
    UploadComponent,
    NoPageComponent,
    DialogBodyComponent
  ],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
     RouterModule, MomentModule, MaterialModule
  ],

  exports: [
    HeaderComponent,
    FooterComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CategoryWidgetComponent,
    UserWidgetComponent,
    FromNowPipe, MomentModule,
    UploadComponent,
    DialogBodyComponent

   ],
   providers: [UploadService],
   entryComponents: [DialogBodyComponent]
})
export class SharedModule { }
