import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
email: string;


  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  async resetPassword(email) {
  await this.auth.resetPassword(this.email);
  return await this.router.navigate(['signin']);
}
}

// 1150780 1158781 1151453
