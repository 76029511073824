<ng-container *ngIf="user">


    <section class="section-padding">
        <div class="container">

            <div class="team-tab" role="tabpanel">

                <button class="waves-effect waves-light btn green" (click)="editing=false" *ngIf="editing"><i class="fa fa-check"></i> listo</button>
                <button class="waves-effect waves-light btn pink" (click)="editing=true" *ngIf="!editing"><i class="fa fa-pencil"></i> Editar</button>
                <!-- Tab panes -->
                <div class="panel-body">
                    <div class="tab-content">
                        <div role="tabpanel" class="tab-pane fade in active" id="team-1">
                            <div class="row">
                                <div class="col-md-4 col-sm-3">
                                    <figure class="team-img text-center">
                                        <img [src]='user.photoURL' class="img-responsive" alt="Image">

                                        <ul class="team-social-links list-inline">

                                            <li><a target="_blanc" [href]="user.facebook"><i class="fa fa-facebook"></i></a></li>
                                            <li><a target="_blanc" [href]="user.twitter"><i class="fa fa-twitter"></i></a></li>

                                            <li><a target="_blanc" [href]="user.instagram"><i class="fa fa-instagram"></i></a></li>
                                            <li><a target="_blanc" [href]="user.youtube"><i class="fa fa-youtube-square"></i></a></li>
                                            <li><a target="_blanc" [href]="user.soundcloud"><i class="fa fa-soundcloud"></i></a></li>
                                        </ul>
                                    </figure>
                                </div>
                                <!-- /.col-md-4 -->

                                <div class="col-md-8 col-sm-9">

                                    <div class="team-intro">
                                        <h3>{{user.displayName}} <small>{{user.title}}</small></h3>
                                        <p>{{user.bio}}</p>
                                    </div>


                                    <div class="team-skill">
                                        <div class="row">

                                            <div class="col-sm-6">

                                                <div class="team-intro">
                                                    <h3><small>{{user.location}}</small></h3>

                                                </div>

                                                <!-- progress-section end -->
                                            </div>



                                            <!-- /.col-md-6 -->


                                        </div>
                                        <!-- /.row -->

                                    </div>
                                    <!--team-skill end -->
                                </div>
                                <!-- col-md-8 -->
                            </div>
                            <!-- row -->
                        </div>
                        <!--team-1 end-->


                    </div>
                    <!--tab-content end -->
                </div>

            </div>
            <!--tab-pan end -->
        </div>
        <!-- /.container -->

        <section [hidden]="!editing" class="section-padding">
            <div class="container">
                <!-- form -->
                <div class="row">
                    <div class="col-md-8">
                        <form (ngSubmit)="updateUser()">

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-field">
                                        <input type="text" name="title" [(ngModel)]="user.title" class="form-control">
                                        <label for="title">Titulo</label>
                                    </div>

                                </div>
                                <!-- /.col-md-6 -->

                                <div class="col-md-6">
                                    <div class="input-field">
                                        <label class="sr-only" for="email">Website</label>
                                        <input type="text" name="website" [(ngModel)]="user.website" class="validate">
                                        <label for="website" data-error="wrong" data-success="right">Website</label>
                                    </div>
                                </div>
                                <!-- /.col-md-6 -->
                            </div>
                            <!-- /.row -->

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-field">
                                        <input type="text" name="facebook" [(ngModel)]="user.facebook" class="validate">
                                        <label for="facebook">Facebook</label>
                                    </div>UploadService
                                </div>
                                <!-- /.col-md-6 -->

                                <div class="col-md-6">
                                    <div class="input-field">
                                        <input type="text" name="twitter" [(ngModel)]="user.twitter" class="validate">
                                        <label for="twitter">Twitter</label>
                                    </div>
                                </div>
                                <!-- /.col-md-6 -->
                            </div>
                            <!-- /.row -->

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-field">
                                        <input type="text" name="instagram" [(ngModel)]="user.instagram" class="validate">
                                        <label for="instagram">Instagram</label>
                                    </div>
                                </div>
                                <!-- /.col-md-6 -->

                                <div class="col-md-6">
                                    <div class="input-field">
                                        <input type="text" name="youtube" [(ngModel)]="user.youtube" class="validate">
                                        <label for="youtube">Youtube</label>
                                    </div>
                                </div>
                                <!-- /.col-md-6 -->
                            </div>
                            <!-- /.row -->

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-field">
                                        <input type="text" name="soundcloud" [(ngModel)]="user.soundcloud" class="validate">
                                        <label for="soundcloud">SoundCloud</label>
                                    </div>
                                </div>
                                <!-- /.col-md-6 -->

                                <div class="col-md-6">UploadService
                                    <div class="input-field">
                                        <input type="text" name="location" [(ngModel)]="user.location" class="validate">
                                        <label for="location">Ubicacion</label>
                                    </div>
                                </div>
                                <!-- /.col-md-6 -->
                            </div>
                            <!-- /.row -->

                            <div class="input-field">
                                <textarea name="bio" [(ngModel)]="user.bio" class="form-control" class="materialize-textarea"></textarea>
                                <label for="bio">Biografia</label>
                            </div>

                            <button type="submit" name="submit" class="waves-effect waves-light btn submit-button pink mt-30">Guardar</button>
                        </form>
                        <br />
                    </div>



                    <div class="container">
                        <div class="row">

                        </div>
                        <form>
                            <div class="row">
                                <div class="text-info">
                                    <h3 class="section-title text-info">Cambiar Correo y/o Nombre</h3>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" placeholder="Cambia tu Nombre" name="displayName" [(ngModel)]="user.displayName">
                                </div>

                                <div class="col-auto">
                                    <button (click)="updateProfile()" class="waves-effect waves-circle waves-light btn-floating btn-large pink">
                            <i class="material-icons">save</i>
                          </button>
                                    <!-- <button type="submit" class="btn btn-primary mb-2">Submit</button> -->
                                </div>
                            </div>
                        </form>


                        <form>
                            <div class="row">
                                <div class="col-md-8">
                                    <input type="text" class="form-control" placeholder="Cambia tu Correo">
                                </div>
                                <div class="col-auto">
                                    <button (click)="updateEmail()" class="waves-effect waves-circle waves-light btn-floating btn-large pink">
                              <i class="material-icons">save</i>
                            </button>
                                    <!-- <button type="submit" class="btn btn-primary mb-2">Submit</button> -->
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

        </section>
        <!-- /.col-md-8 -->



        <!--  -->
    </section>


    <!-- Grid News -->
    <section class="section-padding grid-news-hover grid-blog">
        <div class="container">

            <div class="row">
                <div id="blogGrid">

                    <!-- <app-post-dashboard></app-post-dashboard> -->
                    <app-upload [path]="path" [meta]="meta"></app-upload>
                </div>
            </div>
            <!-- /.row -->

            <!-- <ul class="pagination post-pagination text-center mt-50">
                <li><a href="#." class="waves-effect waves-light"><i class="fa fa-angle-left"></i></a></li>
                <li><span class="current waves-effect waves-light">1</span></li>
                <li><a href="#." class="waves-effect waves-light">2</a></li>
                <li><a href="#." class="waves-effect waves-light"><i class="fa fa-angle-right"></i></a></li>
            </ul> -->


        </div>
    </section>

</ng-container>

<!-- add this script to show twitter feed in sidebar -->
<script>
    if ($('#twitter-gallery-feed').length > 0) {

        var twitterWidgetConfig = {
            id: "613424231099953152", //put your Widget ID here
            domId: "twitter-gallery-feed",
            maxTweets: 3,
            enableLinks: true,
            showUser: false,
            showTime: false,
            showInteraction: false,
            customCallback: handleTweets
        };

        twitterFetcher.fetch(twitterWidgetConfig);

        function handleTweets(tweets) {
            var x = tweets.length;
            var n = 0;
            var html = "";
            while (n < x) {
                html += '<div class="item">' + tweets[n] +
                    "</div>";
                n++
            }

            $(".twitter-widget").html(html);
            $(".twitter_retweet_icon").html(
                '<i class="fa fa-retweet"></i>');
            $(".twitter_reply_icon").html(
                '<i class="fa fa-reply"></i>');
            $(".twitter_fav_icon").html(
                '<i class="fa fa-star"></i>');
            $(".twitter-widget").owlCarousel({
                items: 1,
                loop: true,
                autoplay: true,
                autoHeight: true
            });
        }

    }
</script>
