<section class="error-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-sm-5">
                <i class="fa fa5-podcast"></i>
            </div>

            <div class="col-sm-7">
                <div class="error-info">
                    <h1 class="mb-30">404</h1>
                    <span class="error-sub">OOPS! ESTA PAGINA NO PUDO SER ENCONTRADA</span>

                    <p>Sorry, but we can’t seem to find the page you are looking for.</p>
                    <a class="btn btn-lg waves-effect waves-light" href="index.html">Take Me Home</a>
                </div>
            </div>
        </div>
    </div>
</section>
