import { Component, OnInit } from '@angular/core';

import { InfoPageService } from '../../../services/info-page.service';


@Component({
  selector: 'app-live-detail',
  templateUrl: './live-detail.component.html',
  styleUrls: ['./live-detail.component.css']
})
export class LiveDetailComponent implements OnInit {

  constructor(public infoPageService: InfoPageService) { }

  ngOnInit(): void {

  }

}
