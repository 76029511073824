import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



import { UserService } from './user.service';
import { SharedModule } from './shared/shared.module';


import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserListItemComponent } from './user-list-item/user-list-item.component';
import { PostModule } from '../post/post.module';

const routes: Routes = [
  {
    path: 'me',
    component: UserDashboardComponent,
    // canActivate: [RoutingGuard],
    data: { title: 'Dashboard' }
  },

  { path: 'users', component: UserListComponent, data: { title: 'Users' } },
  {
    path: 'users/:id',
    component: UserDetailComponent,
    data: { title: 'Profile' }
  }
];


@NgModule({
  declarations: [UserDashboardComponent, UserDetailComponent, UserListComponent, UserListItemComponent],
  imports: [
    SharedModule,
    RouterModule,
    PostModule
  ],
  exports: [
    UserListItemComponent
  ]
})
export class UserModule { }
