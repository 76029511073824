<section>

    <!--promo default white box start-->
    <div class="ptb-30" *ngIf="user">
        <div class="container">
            <div class="promo-box border-box">
                <div class="promo-info">
                    <!-- <img [src]="user.photoURL ||'//:0'" alt="{{user.displayName }}"> -->
                    <h2 class="text-extrabold text-uppercase">{{ user.displayName }}</h2>
                    <p> {{ user.email }}</p>
                </div>
                <div class="promo-btn">
                    <img [src]="user.photoURL ||'//:0'" alt="{{user.displayName }}">
                    <!-- <a href="#" class="btn btn-lg waves-effect waves-light">Buy now</a> -->
                </div>
            </div>
        </div>
    </div>
    <!--promo default box end-->
</section>
