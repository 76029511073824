<!--footer 1 start -->
<footer class="footer footer-one">
    <div class="primary-footer brand-bg">
        <div class="container">
            <a href="#top" class="page-scroll btn-floating btn-large pink back-top waves-effect waves-light tt-animate btt" data-section="#top">
                <i class="material-icons">&#xE316;</i>
            </a>

            <div class="row">
                <div class="col-md-3 widget clearfix">
                    <h2 class="white-text">La Grasa Digital</h2>
                    <p>La música es un idioma que no habla en palabras particulares. Habla en las emociones, y si está en los huesos, está en los huesos.</p>

                    <ul class="social-link tt-animate ltr">
                        <li><a target="_blanc" [href]="infoPageService.info.facebook"><i class="fa fa-facebook"></i></a></li>
                        <li><a target="_blanc" [href]="infoPageService.info.twitter"><i class="fa fa-twitter"></i></a></li>
                        <li><a target="_blanc" [href]="infoPageService.info.instagram"><i class="fa fa-instagram"></i></a></li>
                        <li><a target="_blanc" [href]="infoPageService.info.youtube"><i class="fa fa-youtube"></i></a></li>
                        <li><a target="_blanc" [href]="infoPageService.info.twitch"><i class="fa fa-twitch"></i></a></li>

                    </ul>
                </div>
                <!-- /.col-md-3 -->

                <div class="col-md-3 widget">
                    <h2 class="white-text">Paginas Amigas</h2>

                    <ul class="footer-list">
                        <li><a href="#">Disponible</a></li>
                        <li><a href="#">Disponible</a></li>
                        <li><a href="#">Disponible</a></li>
                        <li><a href="#">Disponible</a></li>
                        <li><a [routerLink]="['/privacy']">Politica de Privacidad</a></li>
                    </ul>
                </div>
                <!-- /.col-md-3 -->

                <div class="col-md-3 widget">
                    <h2 class="white-text">Twitter Feed</h2>

                    <div id="twitterfeed" class="twitter-widget-wrapper">
                        <!-- html code injected via javascript -->
                    </div>
                </div>
                <!-- /.col-md-3 -->


                <div class="col-md-3 widget">
                    <h2 class="white-text">News Letter Widget</h2>

                    <form>
                        <div class="form-group clearfix">
                            <label class="sr-only" for="subscribe">Email address</label>
                            <input type="email" class="form-control" id="subscribe" placeholder="Email address">
                            <button type="submit" class="tt-animate ltr"><i class="fa fa-long-arrow-right"></i></button>
                        </div>
                    </form>

                    <h2 class="white-text">Categorias</h2>
                    <app-category-widget>
                    </app-category-widget>

                    <!-- <div class="widget-tags" *ngFor="let category of categoryService |async">
                        <h2 class="white-text">Categorias</h2>
                        <a href="#">{{category.categoryName}}</a>
                        <a href="#">Design</a>
                        <a href="#">Google</a>
                        <a href="#">Gallery</a>
                        <a href="#">Flat Design</a>
                        <a href="#">Clean</a>
                        <a href="#">Portfolio</a>
                    </div> -->

                    <!-- /.widget-tags -->
                </div>
                <!-- /.col-md-3 -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container -->
    </div>
    <!-- /.primary-footer -->

    <div class="secondary-footer brand-bg darken-2">
        <div class="container">
            <span class="copy-text">Copyright &copy; {{copyrightYear}} <a href="#">La Grasa Digital</a> &nbsp;  | &nbsp;  Desarrollado por <a target="_blanc" [href]="infoPageService.info.developer_site">G7 Soluciones</a></span>
        </div>
        <!-- /.container -->
    </div>
    <!-- /.secondary-footer -->
</footer>
<!--footer 1 end-->
