import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../user/shared/shared.module';

import { PostService } from './post.service';


import { PostDashboardComponent } from './post-dashboard/post-dashboard.component';
import { PostDetailComponent } from './post-detail/post-detail.component';
import { PostListComponent } from './post-list/post-list.component';
import { PostListItemComponent } from './post-list-item/post-list-item.component';

import { PostCategoryListComponent } from './post-category-list/post-category-list.component';
import { PostCategoryDetailComponent } from './post-category-detail/post-category-detail.component';
import { MaterialModule } from '../material.module';

const routes: Routes = [
  { path: 'post', component: PostListComponent },
  { path: 'post/:id', component: PostDetailComponent }
];


@NgModule({
  declarations: [
    PostDashboardComponent, PostDetailComponent,
    PostListComponent, PostListItemComponent, PostCategoryListComponent, PostCategoryDetailComponent ],
  imports: [
    CommonModule, SharedModule, RouterModule, MaterialModule
  ],
  exports: [PostDashboardComponent],
  providers: [PostService]
})
export class PostModule { }
