<div class="row">

    <div class="col-md-8 col-md-offset-2">
        <br>
        <h1>Agregar Categoria</h1>
        <br>

        <form class="col s12" [formGroup]="categoryForm" (ngSubmit)="saveCategory()">
            <!-- Upload Buttom -->

            <div class="form-group">
                <label for="categoryName">Nombre de la categoria<span class="require">*</span></label>
                <input type="text" class="form-control" formControlName="categoryName" />
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-primary">
            Guardar
         </button>

            </div>
        </form>
    </div>

</div>
