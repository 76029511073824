import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoPageService } from '../../../services/info-page.service';
import { DialogBodyComponent } from '../dialog-body/dialog-body.component';
import { SigninComponent } from '../../../auth/signin/signin.component';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  animal: string;
  name: string;

  constructor( public infoPageService: InfoPageService , public dialog: MatDialog) { }

  openDialog(): void {
    const dialogRef = this.dialog.open(SigninComponent, {
      // width: '250px',
      // data: {name: this.name, animal: this.animal}
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   this.animal = result;
    // });
  }

  ngOnInit(): void {
  }

}
