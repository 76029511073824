import { Component, OnInit } from '@angular/core';
import { NewspaperService } from '../newspaper.service';
import { Observable } from 'rxjs';
import { Newspaper } from '../newspaper.model';

@Component({
  selector: 'app-news-paper-list',
  templateUrl: './news-paper-list.component.html',
  styleUrls: ['./news-paper-list.component.css']
})
export class NewsPaperListComponent implements OnInit {
newspapers: Observable<Newspaper[]>;


  constructor( private newsPaperService: NewspaperService ) { }

  ngOnInit(): void {
  this.newspapers = this.newsPaperService.getNewspaper();
  }

}
