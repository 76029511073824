import { Pipe, PipeTransform } from '@angular/core';

import * as ngmoment from 'ngx-moment';


@Pipe({
  name: 'amTimeAgo'
})
export class AmTimeAgoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

}
