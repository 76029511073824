import { Injectable } from '@angular/core';
import {  HttpClient } from '@angular/common/http';
import { InfoPage } from '../interfaces/info-page.interface';

@Injectable({
  providedIn: 'root'
})
export class InfoPageService {

  info: InfoPage = {};
  cargada =  false;

  staff: any[] = [];

  constructor( private http: HttpClient) {

    this.cargarInfo();
    this.cargarStaff();
  }

private cargarInfo() {

    // Leer el archivo JSON
    this.http.get('assets/data/data-page.json')
    .subscribe( (resp: InfoPage ) => {

      this.cargada = true;
      this.info = resp;
  });
}

private cargarStaff() {

// Leer el archivo JSON

this.http.get('https://lagrasadigital-com1.firebaseio.com/equipo.json')
.subscribe( (resp: any[]) => {

  this.staff = resp;

  // console.log(resp);
});
}

}
