<!-- Grid News -->
<ng-container *ngIf="user">
    <section [hidden]="editing" class="section-padding grid-news-hover grid-blog">
        <div class="container">
            <div>
                <div class="row">
                    <div class="col-md-9">
                        <div class="row">
                            <div>

                                <div class="col-xs-12 col-sm-6 col-md-6 blog-grid-item">
                                    <article class="post-wrapper">

                                        <mat-card class="card">
                                            <mat-card-header>
                                                <div mat-card-avatar class="example-header-image"></div>
                                                <mat-card-title>{{post.title}}</mat-card-title>
                                                <mat-card-subtitle>{{post.author}} {{ post.published | fromNow }}</mat-card-subtitle>
                                            </mat-card-header>
                                            <img mat-card-image src="{{post.image}}" alt="{{post.title}}" [hidden]="post.image == null">
                                            <mat-card-content>
                                                <!-- <p>
                                          The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan. A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally bred for hunting.
                                      </p> -->
                                            </mat-card-content>
                                            <mat-card-actions align="end">
                                                <button mat-icon-button (click)="trending(post.trending)">
                                            <span>{{post.trending}}</span>
                                            <mat-icon>whatshot</mat-icon>
                                        </button>
                                                <button mat-icon-button (click)="delete(post.id)" *ngIf="auth.currentUserId === post.authorId">
                                        <mat-icon>delete</mat-icon>
                                      </button>
                                                <button mat-icon-button (click)="editing=true" *ngIf="auth.currentUserId === post.authorId">
                                          <mat-icon>edit</mat-icon>
                                     </button>
                                            </mat-card-actions>
                                        </mat-card>

                                    </article>
                                    <!-- /.post-wrapper -->
                                </div>
                                <!-- /.col-md-4 -->

                            </div>
                            <!-- /#blogGrid -->


                        </div>
                        <!-- /.row -->
                    </div>
                    <div class="col-md-3">
                        <div class="tt-sidebar-wrapper" role="complementary">
                            <!-- <div class="widget widget_search">
                                <form role="search" method="get" class="search-form">
                                    <input type="text" class="form-control" value="" name="s" id="s" placeholder="Write any keywords">
                                    <button type="submit"><i class="fa fa-search"></i></button>
                                </form>
                            </div> -->
                            <!-- /.widget_search -->


                            <div class="widget widget_categories">
                                <h3 class="widget-title">Categorias</h3>
                                <ul>
                                    <li><a href="#">Technology</a></li>
                                    <li><a href="#">Media</a></li>
                                    <li><a href="#">Video</a></li>
                                    <li><a href="#">Audio</a></li>
                                    <li><a href="#">Design</a></li>
                                    <li><a href="#">Material</a></li>
                                </ul>
                                <!-- <app-category-widget>
                      </app-category-widget> -->
                            </div>

                            <!-- /.widget_categories -->


                            <div class="widget widget_tt_twitter">
                                <i class="fa fa-twitter"></i>
                                <div id="twitter-gallery-feed">
                                    <div class="twitter-widget"></div>
                                    <!-- this div is required for carousel injected by javascript -->
                                    <!-- html code injected via javascript -->
                                </div>

                            </div>
                            <!-- /.widget_tt_twitter -->

                        </div>
                        <!-- /.tt-sidebar-wrapper -->
                    </div>
                </div>
            </div>




        </div>
        <!-- /.container -->
        <!-- [hidden]="!editing"  -->

        <!-- <section class="section-padding grid-news-hover grid-blog" [hidden]="!editing"> -->
        <div>
            <div class="container">
                <div class="row">

                    <div [hidden]="!editing" class="col-xs-12 col-sm-8 col-md-8 blog-grid-item">

                        <mat-card>
                            <mat-card-content>

                                <section>
                                    <input type="file" name="file" id="file" (change)="uploadPostImage($event)">
                                    <div [hidden]="!uploadPercent">
                                        <mat-progress-bar mode="determinate" value="{{ uploadPercent | async }}"></mat-progress-bar>
                                    </div>
                                    <div class="image-preview" [hidden]="!imageURL">
                                        <img [src]="imageURL || '//:0' ">
                                    </div>
                                </section>
                                <br>
                                <form #postForm="ngForm" (ngSubmit)="update()">

                                    <mat-form-field>
                                        <input matInput placeholder="Post title" [(ngModel)]="post.title" name="title">
                                    </mat-form-field>

                                    <mat-form-field>
                                        <mat-label>Categoria</mat-label>
                                        <mat-select [(ngModel)]="post.category" name="category">
                                            <mat-option *ngFor="let category of categories" [value]="category">
                                                {{category}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <input type="url" matInput placeholder="Fuente" [(ngModel)]="post.fuente" name="fuente">
                                    </mat-form-field>


                                    <mat-form-field>
                                        <textarea rows="12" matInput placeholder="Post content" [(ngModel)]="post.content" name="content"></textarea>
                                    </mat-form-field>

                                    <mat-slide-toggle [(ngModel)]="post.draft" name="draft">
                                        Publicar?
                                    </mat-slide-toggle>
                                    <br><br>
                                    <mat-card-actions align="end">

                                        <button mat-raised-button type="submit" color="accent">Guardar</button>
                                        <!-- <button mat-icon-button (click)="trending(post.trending)">
                          <span>{{post.trending}}</span>
                          <mat-icon>whatshot</mat-icon>
                        </button> -->
                                    </mat-card-actions>
                                </form>

                            </mat-card-content>



                        </mat-card>

                    </div>
                </div>


            </div>
        </div>
    </section>

    <!-- </section> -->
    <!-- Grid News End -->
</ng-container>
