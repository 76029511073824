<section class="section-padding banner-wrapper login-alt banner-6 fullscreen-banner">
    <div class="container">

        <div class="login-wrapper">
            <div class="card-wrapper"></div>
            <div class="card-wrapper">
                <h1 class="title">Iniciar sesión</h1>
                <form [formGroup]="signInForm" (ngSubmit)="signIn()">
                    <div class="input-container">
                        <input type="text" [formControl]="email" id="email" required="required" />
                        <label for="email">Email</label>

                        <div class="bar"></div>

                    </div>
                    <div class="input-container">
                        <input type="password" [formControl]="password" id="password" required="required" />
                        <label for="password">Contrase&ntilde;a</label>
                        <div class="bar"></div>
                    </div>
                    <div class="button-container">
                        <!-- <a href="#." class="btn btn-lg btn-block waves-effect waves-light">Login</a> -->
                        <button type="submit" class="btn btn-lg btn-block waves-effect waves-light">Acceder</button>
                    </div>
                    <div class="footer"><a routerLink="/reset-password" target="_blank">Olvid&oacute;  Contrase&ntilde;a?</a></div>
                </form>
            </div>

        </div>

    </div>
</section>