import { Component, OnInit, Input } from '@angular/core';
import { Post } from '../post.model';
import { PostService } from '../post.service';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Category } from '../../user/shared/category.model';
import { CategoryService } from '../../user/shared/category.service';
import { User } from 'src/app/user/user.model';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-post-list-item',
  templateUrl: './post-list-item.component.html',
  styleUrls: ['./post-list-item.component.css']
})
export class PostListItemComponent implements OnInit {

  @Input()
  post: Post;


  editing = false;
  user: User;

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  imageURL: string;
  // categories: Category[] = [];

   categories: any [] = ['Videos', 'Musica', 'Noticias', 'Entretenimiento'];

  constructor(
    private userService: UserService,
    private postService: PostService,
    public auth: AuthService,
    private storage: AngularFireStorage
  ) { }

  ngOnInit(): void {
    this.getUser();

   }

   getUser() {
    return this.auth.user.subscribe(user => (this.user = user));
  }

  delete(id: string) {
    this.postService.delete(id);
  }

  update() {
    const formData = {
      title: this.post.title,
      image: this.imageURL || this.post.image,
      category: this.post.category,
      content: this.post.content,
      draft: this.post.draft
    };
    this.postService.update(this.post.id, formData);
    this.editing = false;
  }



  async uploadPostImage(event) {
    const file = event.target.files[0];
    const path = `posts/${file.name}`;

    if (file.type.split('/')[0] !== 'image') {
      return alert('only image files');
    } else {
      const task = await this.storage.upload(path, file);
      const ref = this.storage.ref(path);
      this.downloadURL   = ref.getDownloadURL();
      // this.uploadPercent = (task.totalBytes / 100 );
      // console.log('Image Uploaded!');
      this.downloadURL.subscribe(url => this.imageURL = url);
    }
  }

  trending(value: number) {
    if (this.post.id) {
      this.postService.update(this.post.id, {trending: value + 1});
    }

  }
}
