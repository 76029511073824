import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PagesComponent } from './pages/pages.component';

import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';

import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { UserDashboardComponent } from './user/user-dashboard/user-dashboard.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { AuthModule } from './auth/auth.module';
import { PostListComponent } from './post/post-list/post-list.component';
import { PostDetailComponent } from './post/post-detail/post-detail.component';
import { NewsPaperListComponent } from './pages/newspaper/news-paper-list/news-paper-list.component';
import { NewsPaperComponent } from './pages/newspaper/news-paper/news-paper.component';
import { CategoryComponent } from './user/shared/category/category.component';
import { CategoryListComponent } from './user/shared/category-list/category-list.component';
import { UnderConstructionComponent } from './user/shared/under-construction/under-construction.component';
import { PolicyPrivacyComponent } from './pages/policy-privacy/policy-privacy.component';

import { LiveDetailComponent } from './pages/lives/live-detail/live-detail.component';

import { PagesModule } from './pages/pages.module';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { ChatListComponent } from './chat/chat-list/chat-list.component';
import { ChatDetailComponent } from './chat/chat-detail/chat-detail.component';
import { LiveListComponent } from './pages/lives/live-list/live-list.component';
import { LiveAddComponent } from './pages/lives/live-add/live-add.component';


const routes: Routes = [


  {
    path: '',
    component: PagesComponent,
    children: [
      { path: 'post', component: PostListComponent, data: { title: 'post'}},
      { path: 'post/:id', component: PostDetailComponent},
      { path: 'about', component: AboutComponent},
      { path: 'chat', component: ChatListComponent},
      { path: 'chat/detail', component: ChatDetailComponent},
      // { path: 'chat', loadChildren: './chat/chat.module/'},

      { path: 'signin', component: SigninComponent, data: { title: 'Sign in'}},
      { path: 'signup', component: SignupComponent, data: { title: 'Sign up'}},
      { path: 'newspaper', component: NewsPaperListComponent},
      { path: 'liveadd', component: LiveAddComponent},
      { path: 'shows', component: LiveListComponent},
      { path: 'news', component: NewsPaperComponent},
      { path: 'privacy', component: PolicyPrivacyComponent},
      { path: 'contact', component: ContactComponent},
      { path: 'category', component: CategoryListComponent},
      { path: 'add-category', component: CategoryComponent},
      { path: 'me', component: UserDashboardComponent, data: { title: 'Dashboard'}},
      { path: 'users', component: UserListComponent, data: { title: 'Users'}},
      { path: 'users/:id', component: UserDetailComponent, data: { title: 'Profile'}},
      { path: '**', pathMatch: 'full', redirectTo: 'post'}
    ]
  },
  // { path: 'signup', component: SignupComponent},
  { path: 'live', component: LiveDetailComponent},
  { path: '**', pathMatch: 'full', redirectTo: 'post'},
  // { path: 'post', loadChildren: () => import('./post/post.module').then(m => m.PostModule) },
];




@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true}),
    // AuthRoutingModule,
    AuthModule,
     PagesModule],
  exports: [RouterModule]
})

export class AppRoutingModule { }
