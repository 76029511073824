import { Component, OnInit } from '@angular/core';

import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';

import { User } from '../user.model';
import { AuthService } from '../../core/auth.service';
import { UserService } from '../user.service';

import * as $ from '../../../assets/js/jquery-3.2.1.min.js';

// declare function init_plugins();
// declare function init_plugins_smooth();

// declare function init_punchtool();

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
  editing = false;
  user: User;

  task: AngularFireUploadTask;

  path: string;
  meta: object;
  uploadType: boolean;

  constructor(private auth: AuthService, private userService: UserService, private storage: AngularFireStorage) { }

  ngOnInit(): void {

    // init_punchtool();
    // init_plugins();
    // init_plugins_smooth();
    this.getUser();
    this.setUploadData();
  }

setUploadData() {
  return this.auth.user.subscribe( user => {

    if (user) {
            // wrap this in a if statement
      // to avoid error msg on logout

    this.path = `users/${user.uid}/uploads`;
    this.meta = { uploader: user.uid, website: 'http://lagrasadigital.com/'};
       // true means Collection upload
        // false means document field upload
    this.uploadType = true;

    }
  });
}

  getUser() {
    return this.auth.user.subscribe(user => (this.user = user));
  }

updateProfile() {
  return this.userService.updateProfileData(
    this.user.displayName,
    this.user.photoURL
  );
}

updateEmail() {
return this.userService.updateEmailData(this.user.email);
}


uploadPhotoURL(event): void {
  const file = event.target.files[0];
  const path = `users/${this.user.uid}/photos/${file.name}`;
  if (file.type.split('/')[0] !== 'image') {
    return alert('only images allowed');
  } else {
    this.task = this.storage.upload(path, file);

    // add this ref
    const ref = this.storage.ref(path);

    // and change the observable here
    ref.getDownloadURL().subscribe(url => {
      this.userService.updateProfileData(this.user.displayName, url);
    });
  }
}


updateUser() {
  const data = {
    title:       this.user.title || null,
    bio:        this.user.bio || null,
    facebook:   this.user.facebook || null,
    twitter:    this.user.twitter || null,
    instagram:  this.user.instagram || null,
    youtube:    this.user.youtube || null,
    soundcloud: this.user.soundcloud || null,
    website:    this.user.website || null,
    location:   this.user.location || null,
  };
  return this.userService.updateUserData(data);
}

}
