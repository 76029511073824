import { Component, OnInit } from '@angular/core';
import { InfoPageService } from '../../../services/info-page.service';
import { CategoryService } from '../category.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

copyrightYear: number = new Date().getFullYear();

  constructor( public infoPageService: InfoPageService, public categoryService: CategoryService) { }

  ngOnInit(): void {
  }

}
