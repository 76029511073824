// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,

  firebaseConfig : {
    apiKey: 'AIzaSyBL4kjH4HZsc907izqNG82rkhHvtZHJm04',
    authDomain: 'lagrasadigital-com1.firebaseapp.com',
    databaseURL: 'https://lagrasadigital-com1.firebaseio.com',
    projectId: 'lagrasadigital-com1',
    storageBucket: 'lagrasadigital-com1.appspot.com',
    messagingSenderId: '924609182309',
    appId: '1:924609182309:web:035fd7ea2467c8d5d4248c',
    measurementId: 'G-ED65L1W99D'
  }

  // firebaseConfig : {
  //   apiKey: "AIzaSyBL4kjH4HZsc907izqNG82rkhHvtZHJm04",
  //   authDomain: "lagrasadigital-com1.firebaseapp.com",
  //   databaseURL: "https://lagrasadigital-com1.firebaseio.com",
  //   projectId: "lagrasadigital-com1",
  //   storageBucket: "lagrasadigital-com1.appspot.com",
  //   messagingSenderId: "924609182309",
  //   appId: "1:924609182309:web:035fd7ea2467c8d5d4248c",
  //   measurementId: "G-ED65L1W99D"
  // }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
