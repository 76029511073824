<!-- Top bar -->
<div class="top-bar light-blue">
    <div class="container">
        <div class="row">
            <!-- Social Icon -->
            <div class="col-md-3">
                <!-- Social Icon -->
                <ul class="list-inline social-top tt-animate btt">

                    <li><a target="_blanc" [href]="infoPageService.info.facebook"><i class="fa fa-facebook"></i></a></li>
                    <li><a target="_blanc" [href]="infoPageService.info.twitter"><i class="fa fa-twitter"></i></a></li>
                    <li><a target="_blanc" [href]="infoPageService.info.instagram"><i class="fa fa-instagram"></i></a></li>
                    <li><a target="_blanc" [href]="infoPageService.info.youtube"><i class="fa fa-youtube"></i></a></li>
                    <li><a target="_blanc" [href]="infoPageService.info.twitch"><i class="fa fa-twitch"></i></a></li>

                </ul>
            </div>

            <div class="col-md-5 text-right">
                <ul class="topbar-cta no-margin">
                    <li class="mr-20">
                        <a [href]="'mailto:' +  infoPageService.info.email"><i class="material-icons mr-10">&#xE0B9;</i>{{infoPageService.info.email}}</a>
                    </li>
                    <li>
                        <a><i class="material-icons mr-10">&#xE0CD;</i>{{infoPageService.info.bussiness_phone}}</a>
                    </li>

                </ul>
            </div>

            <div class="col-md-4 text-right">
                <ul class="topbar-cta no-margin">
                    <li class="mr-20">
                        <a (click)="openDialog()"><i class="material-icons mr-10">how_to_reg</i>Acceder</a>
                        <!-- <a [routerLink]="['/signin']"><i class="material-icons mr-10">how_to_reg</i>Acceder</a> -->
                    </li>
                    <li>
                        <a [routerLink]="['/signup']"><i class="material-icons mr-10">create</i>Registro</a>
                    </li>

                </ul>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container -->
</div>
<!-- /.top-bar -->


<!--header start-->
<header id="header" class="tt-nav nav-border-bottom">

    <div class="header-sticky light-header ">

        <div class="container">

            <!-- <div class="search-wrapper">
                <div class="search-trigger pull-right">
                    <div class='search-btn'></div>
                    <i class="material-icons">&#xE8B6;</i>
                </div>

                <!-- Modal Search Form
                <i class="search-close material-icons">&#xE5CD;</i>
                <div class="search-form-wrapper">
                    <form action="#" class="white-form">
                        <div class="input-field">
                            <input type="text" name="search" id="search">
                            <label for="search" class="">Search Here...</label>
                        </div>
                        <button class="btn pink search-button waves-effect waves-light" type="submit"><i class="material-icons">&#xE8B6;</i></button>

                    </form>
                </div>

            </div> -->
            <!-- /.search-wrapper -->

            <div id="materialize-menu" class="menuzord">

                <!--logo start-->
                <a [routerLink]="['/post']" class="logo-brand">
                    <img class="retina" src="assets/img/logo.png" alt="" />
                </a>
                <!--logo end-->

                <!--mega menu start-->
                <ul class="menuzord-menu pull-right">

                    <li routerLinkActive="active"><a [routerLink]="['/post']">Inicio</a> </li>

                    <li routerLinkActive="active"><a [routerLink]="['/live']">En Vivo</a> </li>

                    <li routerLinkActive="active"><a [routerLink]="['/about']">Nosotros</a> </li>

                    <li routerLinkActive="active"><a [routerLink]="['/contact']">Contacto</a> </li>

                    <li routerLinkActive="active"><a [routerLink]="['/chat/detail']">Chat</a> </li>

                    <li routerLinkActive="active"><a [routerLink]="['/newspaper']">Periodicos</a> </li>

                    <li routerLinkActive="active"><a [routerLink]="['/shows']">Programas</a> </li>

                </ul>
                <!--mega menu end-->

            </div>
        </div>
    </div>

</header>
<!--header end-->




<!-- start revolution slider 5.0 -->
<section class="rev_slider_wrapper">
    <div class="rev_slider materialize-slider">
        <ul>

            <!-- slide 1 start -->
            <li data-transition="fade" data-slotamount="default" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed="2000" data-thumb="assets/img/banner/bg.jpg" data-rotate="0" data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7"
                data-saveperformance="off" data-title="materialize Material" data-description="">

                <!-- MAIN IMAGE -->
                <img src="assets/img/banner/bg3.png" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="10" class="rev-slidebg" data-no-retina>


                <!-- LAYER NR. 1 -->
                <!-- <div class="tp-caption tp-resizeme" data-basealign="slide" data-x="['right','right','right','right']" data-hoffset="['0','0','-70','-70']" data-y="['top','top','top','top']" data-voffset="['0','0','-30','-30']" data-transform_idle="o:1;" data-transform_in="x:50px;opacity:0;s:600;e:Power2.easeOut;"
                    data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2000" style="z-index: 5;">
                    <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/mic.png">
                    </div>
                </div> -->

                <!-- LAYER NR. 2 -->
                <!-- <div class="tp-caption tp-resizeme" data-x="left" data-y="center" data-voffset="-150" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:600;e:Power2.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;"
                    data-start="2000" style="z-index: 5;">
                    <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/music-notes.png">
                    </div>
                </div> -->

                <!-- LAYER NR. 3 -->
                <div class="tp-caption tp-resizeme" data-x="left" data-y="bottom" data-voffset="100" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:600;e:Power2.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;"
                    data-start="2400" style="z-index: 5;">
                    <div>
                        <a href="https://play.google.com/store/apps/details?id=com.g7soluciones.lagrasadigital"><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/android_logo.png"></a>
                    </div>
                </div>

                <!-- LAYER NR. 4 -->
                <div class="tp-caption tp-resizeme" data-basealign="slide" data-x="['right','right','right','right']" data-hoffset="['0','0','-50','-50']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['0','0','0','0']" data-transform_idle="o:1;" data-transform_in="x:50px;opacity:0;s:600;e:Power2.easeOut;"
                    data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2400" style="z-index: 5;">
                    <div>
                        <a href="https://apps.apple.com/app/id1541341917"><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/iphone-logo.png">
                        </a>
                    </div>
                </div>
                <!--  -->
                <!-- LAYER NR. 5 -->
                <div class="tp-caption NotGeneric-Title tp-resizeme" data-x="center" data-y="center" data-voffset="-100" data-fontsize="['70','70','70','45']" data-lineheight="['70','70','70','50']" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;"
                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:600;e:Power4.easeInOut;" data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;"
                    data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" data-start="800" data-splitin="none" data-splitout="none" data-responsive_offset="on" style="z-index: 5;color:white; white-space: nowrap;">Descargala la app,<br> en Android, Disponible en IOS !!!
                </div>

                <!-- LAYER NR. 6 -->
                <div class="tp-caption tp-resizeme rev-subheading white-text text-center" data-x="center" data-y="center" data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:600;e:Power4.easeInOut;"
                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" data-start="1000" data-splitin="none" data-splitout="none"
                    data-responsive_offset="on" style="z-index: 6; white-space: nowrap;">La mejor programaci&oacute;n musical !!!.
                </div>

                <!-- LAYER NR. 7 -->
                <div class="tp-caption tp-resizeme rev-btn" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['100','100','100','100']" data-width="none" data-height="none" data-whitespace="nowrap"
                    data-transform_idle="o:1;" data-style_hover="cursor:default;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:600;e:Power4.easeInOut;" data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                    data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" data-start="1200" data-splitin="none" data-splitout="none" data-responsive_offset="on" style="z-index: 7; white-space: nowrap;">

                </div>


                <!-- LAYER NR. 8 -->
                <!-- <div class="tp-caption tp-resizeme" data-basealign="slide" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['-70','-70','-170','-170']" data-transform_idle="o:1;"
                    data-transform_in="y:50px;opacity:0;s:600;e:Power2.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2400" style="z-index: 5;">
                    <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/djs.png">
                    </div>
                </div> -->


                <!-- LAYER NR. 8 -->
                <!-- <div class="tp-caption tp-resizeme" data-basealign="slide" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['-120','-120','-170','-170']" data-transform_idle="o:1;"
                    data-transform_in="y:50px;opacity:0;s:600;e:Power2.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2400" style="z-index: 5;">
                    <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/djs.png">
                    </div>
                </div>
            </li> -->
                <!-- slide 1 end -->

                <!-- slide 1 start -->
                <li data-transition="fade" data-slotamount="default" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed="2000" data-thumb="assets/img/banner/bg.jpg" data-rotate="0" data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7"
                    data-saveperformance="off" data-title="materialize Material" data-description="">

                    <!-- MAIN IMAGE -->
                    <img src="assets/img/banner/bg3.png" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="10" class="rev-slidebg" data-no-retina>


                    <!-- LAYER NR. 1 -->
                    <div class="tp-caption tp-resizeme" data-basealign="slide" data-x="['right','right','right','right']" data-hoffset="['0','0','-70','-70']" data-y="['top','top','top','top']" data-voffset="['0','0','-30','-30']" data-transform_idle="o:1;" data-transform_in="x:50px;opacity:0;s:600;e:Power2.easeOut;"
                        data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2000" style="z-index: 5;">
                        <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/mic.png">
                        </div>
                    </div>

                    <!-- LAYER NR. 2 -->
                    <div class="tp-caption tp-resizeme" data-x="left" data-y="center" data-voffset="-150" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:600;e:Power2.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;"
                        data-start="2000" style="z-index: 5;">
                        <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/music-notes.png">
                        </div>
                    </div>

                    <!-- LAYER NR. 3 -->
                    <div class="tp-caption tp-resizeme" data-x="left" data-y="bottom" data-voffset="100" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:600;e:Power2.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;"
                        data-start="2400" style="z-index: 5;">
                        <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/earphones.png">
                        </div>
                    </div>

                    <!-- LAYER NR. 4 -->
                    <div class="tp-caption tp-resizeme" data-basealign="slide" data-x="['right','right','right','right']" data-hoffset="['0','0','-50','-50']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['0','0','0','0']" data-transform_idle="o:1;" data-transform_in="x:50px;opacity:0;s:600;e:Power2.easeOut;"
                        data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2400" style="z-index: 5;">
                        <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/whiskey-glass2.png">
                        </div>
                    </div>

                    <!-- LAYER NR. 5 -->
                    <div class="tp-caption NotGeneric-Title tp-resizeme" data-x="center" data-y="center" data-voffset="-100" data-fontsize="['70','70','70','45']" data-lineheight="['70','70','70','50']" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;"
                        data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:600;e:Power4.easeInOut;" data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;"
                        data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" data-start="800" data-splitin="none" data-splitout="none" data-responsive_offset="on" style="z-index: 5;color:white; white-space: nowrap;">Lo mejor en M&uacute;sica !!!
                    </div>

                    <!-- LAYER NR. 6 -->
                    <div class="tp-caption tp-resizeme rev-subheading white-text text-center" data-x="center" data-y="center" data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:600;e:Power4.easeInOut;"
                        data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" data-start="1000" data-splitin="none" data-splitout="none"
                        data-responsive_offset="on" style="z-index: 6; white-space: nowrap;">La mejor programaci&oacute;n musical !!!.
                    </div>

                    <!-- LAYER NR. 7 -->
                    <div class="tp-caption tp-resizeme rev-btn" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['100','100','100','100']" data-width="none" data-height="none" data-whitespace="nowrap"
                        data-transform_idle="o:1;" data-style_hover="cursor:default;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:600;e:Power4.easeInOut;" data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                        data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" data-start="1200" data-splitin="none" data-splitout="none" data-responsive_offset="on" style="z-index: 7; white-space: nowrap;">

                    </div>


                    <!-- LAYER NR. 8 -->
                    <!-- <div class="tp-caption tp-resizeme" data-basealign="slide" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['-70','-70','-170','-170']" data-transform_idle="o:1;"
          data-transform_in="y:50px;opacity:0;s:600;e:Power2.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2400" style="z-index: 5;">
          <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/djs.png">
          </div>
      </div> -->


                    <!-- LAYER NR. 8 -->
                    <div class="tp-caption tp-resizeme" data-basealign="slide" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['-120','-120','-170','-170']" data-transform_idle="o:1;"
                        data-transform_in="y:50px;opacity:0;s:600;e:Power2.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2400" style="z-index: 5;">
                        <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/djs.png">
                        </div>
                    </div>
                </li>
                <!-- slide 1 end -->

                <!-- slide 2 start -->
                <li data-transition="fade" data-slotamount="default" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed="2000" data-thumb="assets/img/banner/bg.jpg" data-rotate="0" data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7"
                    data-saveperformance="off" data-title="Unique Design" data-description="">

                    <!-- MAIN IMAGE -->
                    <img src="assets/img/banner/bg.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="10" class="rev-slidebg" data-no-retina>


                    <!-- LAYER NR. 1 -->
                    <div class="tp-caption tp-resizeme" data-basealign="slide" data-x="['right','right','right','right']" data-hoffset="['0','0','-170','-170']" data-y="['top','top','top','top']" data-voffset="['0','0','-90','-90']" data-transform_idle="o:1;" data-transform_in="x:50px;opacity:0;s:600;e:Power2.easeOut;"
                        data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2000" style="z-index: 5;">
                        <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/merengue.png">
                        </div>
                    </div>

                    <!-- LAYER NR. 2 -->
                    <div class="tp-caption tp-resizeme" data-basealign="slide" data-x="['left','left','left','left']" data-hoffset="['0','0','0','0']" data-y="['center','center','center','center']" data-voffset="['0','0','0','0']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:600;e:Power2.easeOut;"
                        data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2000" style="z-index: 5;">
                        <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/salsa.png">
                        </div>
                    </div>

                    <!-- LAYER NR. 3 -->
                    <div class="tp-caption tp-resizeme" data-x="['left','left','left','left']" data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['0','0','-90','-90']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:600;e:Power2.easeOut;"
                        data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2400" style="z-index: 5;">
                        <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/bachata.png">
                        </div>
                    </div>

                    <!-- LAYER NR. 4 -->
                    <div class="tp-caption tp-resizeme" data-basealign="slide" data-x="['right','right','right','right']" data-hoffset="['0','0','-150','-150']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['0','0','0','0']" data-transform_idle="o:1;" data-transform_in="x:50px;opacity:0;s:600;e:Power2.easeOut;"
                        data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2400" style="z-index: 5;">
                        <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/romanticos.png">
                        </div>
                    </div>

                    <!-- LAYER NR. 5 -->
                    <div class="tp-caption NotGeneric-Title tp-resizeme" data-x="center" data-y="center" data-voffset="-100" data-fontsize="['70','70','70','45']" data-lineheight="['70','70','70','50']" data-width="none" data-height="none" data-whitespace="nowrap" data-transform_idle="o:1;"
                        data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:600;e:Power4.easeInOut;" data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;"
                        data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" data-start="800" data-splitin="none" data-splitout="none" data-responsive_offset="on" style="z-index: 5; white-space: nowrap; color: white;">La Grasa Digital
                    </div>

                    <!-- LAYER NR. 6 -->
                    <div class="tp-caption tp-resizeme rev-subheading white-text text-center" data-x="center" data-y="center" data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:600;e:Power4.easeInOut;"
                        data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" data-start="1000" data-splitin="none" data-splitout="none"
                        data-responsive_offset="on" style="z-index: 6; white-space: nowrap;">La m&uacute;sica es el centro de nuestro ser: <br> con ella nos enamoramos, sin ella no existimos.
                    </div>

                    <!-- LAYER NR. 7 -->
                    <div class="tp-caption tp-resizeme rev-btn" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['100','100','100','100']" data-width="none" data-height="none" data-whitespace="nowrap"
                        data-transform_idle="o:1;" data-style_hover="cursor:default;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:600;e:Power4.easeInOut;" data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                        data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" data-start="1200" data-splitin="none" data-splitout="none" data-responsive_offset="on" style="z-index: 7; white-space: nowrap;">
                        <!-- <a href="#" class="btn btn-lg  waves-effect waves-light">Explore More</a> -->
                    </div>


                    <!-- LAYER NR. 8 -->
                    <div class="tp-caption tp-resizeme" data-basealign="slide" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['-120','-120','-170','-170']" data-transform_idle="o:1;"
                        data-transform_in="y:50px;opacity:0;s:600;e:Power2.easeOut;" data-transform_out="opacity:0;s:1500;e:Power4.easeIn;s:1500;e:Power4.easeIn;" data-start="2400" style="z-index: 5;">
                        <div><img src="assets/img/banner/dummy.png" alt="" data-lazyload="assets/img/banner/urbana.png">
                        </div>
                    </div>
                </li>
                <!-- slide 2 end -->





        </ul>
    </div>
    <!-- end revolution slider -->
</section>
<!-- end of slider wrapper -->
