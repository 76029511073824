<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="la mejor musica ">
    <meta name="keywords" content="misica, en vivo, emisora, dj">
    <meta name="developer" content="Charles">

    <title>En Vivo</title>

    Access-Control-Allow-Origin: https://www.twitch.tv/


    <!-- HTML5 shim and Respond.js for IE8 support of HTML5 elements and media queries -->
    <!-- WARNING: Respond.js doesn't work if you view the page via file:// -->
    <!--[if lt IE 9]>
          <script src="https://oss.maxcdn.com/html5shiv/3.7.2/html5shiv.min.js"></script>
          <script src="https://oss.maxcdn.com/respond/1.4.2/respond.min.js"></script>
        <![endif]-->
</head>

<body id="top">

    <section class="banner-11 bg-fixed parallax-bg fullscreen-banner valign-wrapper" data-stellar-background-ratio="0.5">
        <div class="valign-cell">
            <div class="container">
                <div class="comingsoon-wrapper text-center">
                    <div class="logo">
                        <a [routerLink]="['/post']">
                            <img src="assets/img/logo.png" alt="">
                        </a>
                    </div>

                    <div class="mb-70">

                        <iframe src="https://player.twitch.tv/?channel=lagrasadigital&parent=lagrasadigital.com" frameborder="0" allowfullscreen="true" scrolling="no" height="378" width="620"></iframe>

                    </div>

                    <div class="countdown-wrapper mb-30 white-text">
                        <!-- Add a placeholder for the Twitch embed -->
                        <!-- Add a placeholder for the Twitch embed -->






                        <!-- /countdown -->
                    </div>
                    <br>

                    <div class="row">
                        <div class="col-md-6 col-md-offset-3">
                            <div class="subscribe-wrapper text-center">

                            </div>
                            <!-- /.subscribe-wrapper -->
                        </div>
                        <!-- /.col-md-6 -->
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.comingsoon-wrapper -->

            </div>
            <!-- /.container -->
        </div>
        <!-- /.valign-cell -->
    </section>



    <footer class="footer footer-four">
        <div class="primary-footer brand-bg text-center">
            <div class="container">

                <a href="#top" class="page-scroll btn-floating btn-large pink back-top waves-effect waves-light tt-animate btt" data-section="#top">
                    <i class="material-icons">&#xE316;</i>
                </a>

                <ul class="social-link tt-animate ltr mt-20">

                    <li> <a target="_blanc" [href]="infoPageService.info.facebook"><i class="fa fa-facebook"></i></a></li>
                    <li><a target="_blanc" [href]="infoPageService.info.twitter"><i class="fa fa-twitter"></i></a></li>
                    <li><a target="_blanc" [href]="infoPageService.info.instagram"><i class="fa fa-instagram"></i></a></li>
                    <li><a target="_blanc" [href]="infoPageService.info.youtube"><i class="fa fa-youtube"></i></a></li>

                    <li><a target="_blanc" [href]="infoPageService.info.twitch"><i class="fa fa-twitch"></i></a></li>
                </ul>

                <hr class="mt-15">

                <div class="row">
                    <div class="col-md-12">
                        <div class="footer-logo">
                            <img src="assets/img/logoW.png" alt="">
                        </div>


                    </div>
                    <!-- /.col-md-12 -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container -->
        </div>
        <!-- /.primary-footer -->

        <div class="secondary-footer brand-bg darken-2 text-center">
            <div class="container">
                <ul>
                    <li><a [routerLink]="['/post']">Inicio</a></li>

                    <li routerLinkActive="active"><a [routerLink]="['/about']">Nosotros</a> </li>

                    <li routerLinkActive="active"><a [routerLink]="['/contact']">Contacto</a> </li>

                    <li routerLinkActive="active"><a [routerLink]="['/chat']">Chat</a> </li>
                    <!-- <li><a href="#">en Vivo</a> </li> -->

                    <li routerLinkActive="active"><a [routerLink]="['/newspaper']">Periodicos</a>

                    </li>
                </ul>
            </div>
            <!-- /.container -->
        </div>
        <!-- /.secondary-footer -->
    </footer>


    <!-- Preloader -->
    <!-- <div id="preloader">
        <div class="preloader-position">
            <img src="assets/img/logo-colored.png" alt="logo">
            <div class="progress">
                <div class="indeterminate"></div>
            </div>
        </div>
    </div> -->
    <!-- End Preloader -->

    <!-- jQuery -->

</body>

</html>
