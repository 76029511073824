<section class="section-padding banner-wrapper login-alt banner-6 fullscreen-banner">
    <div class="container">

        <div class="login-wrapper">
            <div class="card-wrapper"></div>

            <div class="card-wrapper">
                <!-- <div class="toggle"></div> -->
                <h1 class="title">Registrese
                    <div class="close"></div>
                </h1>

                <form [formGroup]="signUpForm" (ngSubmit)="signUp()">
                    <div class="input-container">
                        <input type="text" [formControl]="email" required="required" />
                        <label for="newusername">Email</label>
                        <div class="bar"></div>
                    </div>
                    <div class="input-container">
                        <input type="password" [formControl]="password" required="required" />
                        <label for="newpassword">Contrase&ntilde;a</label>
                        <div class="bar"></div>
                    </div>
                    <div class="input-container">
                        <input type="password" id="repeat-password" required="required" />
                        <label for="repeat-password">Confirme contrase&ntilde;a</label>
                        <div class="bar"></div>
                    </div>
                    <div class="button-container">
                        <!-- <a href="#." class="btn btn-lg btn-block white waves-effect waves-red">Registar</a> -->
                        <button type="submit" class="btn btn-lg btn-block waves-effect waves-light">Registrarse</button>

                    </div>
                </form>
            </div>
        </div>

    </div>
</section>


<!-- Preloader -->
<!-- <div id="preloader">
    <div class="preloader-position">
        <img src="assets/img/logo-colored.png" alt="logo">
        <div class="progress">
            <div class="indeterminate"></div>
        </div>
    </div>
</div> -->
<!-- End Preloader -->