<div class="author-info-wrapper">

    <div class="author-cover">
        <img src="assets/img/blog/author-large-thumb.jpg" alt="">
    </div>

    <div class="author-avatar">
        <img src="assets/img/blog/author-2.jpg" alt="">

        <h2>{{user.displayName ||user.email}}</h2>
        <span>User Interface Designer</span>
    </div>

    <p>All these men were men of conviction. They deeply believed in what they were doing and put their reputations.</p>

    <div class="author-social-links">
        <ul class="list-inline">
            <li><a href="#" target="_blank"><i class="fa fa-facebook"></i></a></li>
            <li><a href="#" target="_blank"><i class="fa fa-twitter"></i></a></li>
            <li><a href="#" target="_blank"><i class="fa fa-google-plus"></i></a></li>
            <li><a href="#" target="_blank"><i class="fa fa-linkedin"></i></a></li>
            <li><a href="#" target="_blank"><i class="fa fa-instagram"></i></a></li>
        </ul>
    </div>
</div>
