<!-- <mat-list>
    <mat-list-item [ngClass]="{'inbound': incoming, 'outbound': !incoming}">
        <img matListAvatar [src]="message.photoURL || '//:0'" alt="{{message.sender}}">
        <p mat-line>{{message.content}}</p>
        <p mat-line>
            <small>{{message.timestamp | fromNow }}</small>
        </p>
    </mat-list-item>
</mat-list> -->

<!-- <div class="content" id="content">
    <div class="container">
        <div class="col-md-12">
            <div class="date">
                <hr>

                <hr>
            </div>
            <div class="message">
                <img class="avatar-md" [src]="message.photoURL || '//:0'" data-toggle="tooltip" data-placement="top" title="{{message.sender}}" alt="avatar">
                <div class="text-main">
                    <div class="text-group">
                        <div class="text">
                            <p>{{message.content}}</p>
                        </div>
                    </div>title="{{message.sender}}"
                    <span>{{message.timestamp | fromNow }}</span>
                </div>
            </div>

        </div>
    </div>
</div> -->


<div class="chats">
    <div class="chat chat-right">
        <div class="chat-avatar">
            <a class="avatar">
                <img [src]="message.photoURL" class="circle" title="{{message.sender}}" alt="{{message.sender}}" />
            </a>
        </div>
        <div class="chat-body">
            <div class="chat-text">
                <p>{{message.content}}</p>
            </div>
            <span>{{message.timestamp | fromNow }}</span>
        </div>
    </div>
</div>
