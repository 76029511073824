<div class="row">

    <div class="col-md-8 col-md-offset-2">
        <br>
        <h1>Agregar Programas</h1>
        <br>
        <section>
            <!-- add #resetMe // we will use it to reference this input field -->
            <input #resetMe type="file" name="file" id="file" (change)="uploadLiveImage($event)">
            <!-- add the "|| !imageURL" to the hidden condition here too as we can't easily reset an observable -->

            <a [href]="downloadURL | async" [hidden]="!downloadURL || !liveImgURL"> click this link {{ downloadURL | async }}</a>

            <div class="materialboxed" width="250" [hidden]="!liveImgURL">
                <img [src]="liveImgURL || '//:0' ">
            </div>


        </section>

        <form class="col s12" [formGroup]="liveForm" (ngSubmit)="saveLive()">
            <!-- Upload Buttom -->

            <div class="form-group">
                <label for="name">Nombre del Programa<span class="require">*</span></label>
                <input type="text" class="form-control" formControlName="name" />
            </div>

            <div class="form-group">
                <label for="desc">Descripcion<span class="require">*</span></label>
                <input type="text" class="form-control" formControlName="desc" />
            </div>
            <div class="form-group">
                <label for="liveUrl">URL</label>
                <input type="text" class="form-control" formControlName="liveUrl" />
            </div>

            <div class="form-group">
                <p><span class="require">*</span> - required fields</p>
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-primary">
            Guardar
         </button>

            </div>
        </form>
    </div>

</div>