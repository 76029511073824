import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';



// import { AuthService } from '../../core/auth.service';
import { PostService } from '../post.service';
import { Post } from '../post.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.css' ]


})
export class PostListComponent implements OnInit {
  posts: Observable<Post[]>;

  constructor( private postService: PostService) {
  }

  ngOnInit(): void {
    this.posts = this.postService.getPosts();


  }

}
