<!-- Hero Section -->
<section class="banner-wrapper parallax-bg banner-8 fullscreen-banner valign-wrapper overlay dark-5" data-stellar-background-ratio="0.5">
    <div class="valign-cell">
        <div class="container">
            <div class="text-center padding-top-110">
                <h1 class="tt-headline rotate-1">
                    <!-- you can use "rotate-1", "letters type", "letters rotate-2", "letters rotate-3", "letters scale", "loading-bar", "slide", "clip is-full-width", "push" -->
                    <span class="tt-words-wrapper">
                          <b class="is-visible">En Desarrollo</b>
                          <b>En construccion</b>
                          <b>Creando este Modulo</b>
                          <b>Programando la Pagina</b>
                      </span>
                </h1>

                <span class="tt-sub-intro">PROXIMAMENTE !!!</span>

            </div>
        </div>
        <!-- /.container -->
    </div>
    <!-- /.valign-cell -->

    <div class="mouse-icon hidden-sm hidden-xs">
        <div class="wheel"></div>
    </div>
</section>
<!-- Hero Section End -->
