<section class="section-padding grid-news-hover grid-blog">
    <div class="container">

        <div class="row">
            <div *ngFor="let newspaper of newspapers | async " id="blogGrid">

                <div class="col-xs-12 col-sm-3 col-md-3 blog-grid-item">
                    <article class="post-wrapper">

                        <div class="thumb-wrapper waves-effect waves-block waves-light">
                            <a target="_blank" href="{{newspaper.url}}"><img src={{newspaper.logo}} class="img-responsive" alt="{{newspaper.name}}"></a>

                        </div>

                        <!-- .post-thumb -->

                        <div class="blog-content">

                            <div class="hover-overlay light-blue"></div>

                            <header class="entry-header-wrapper">
                                <div class="entry-header">
                                    <h2 class="entry-title"><a target="_blank" href="{{newspaper.url}}">{{newspaper.name}}</a></h2>

                                </div>
                                <!-- /.entry-header -->
                            </header>
                            <!-- /.entry-header-wrapper -->
                        </div>
                        <!-- /.blog-content -->

                    </article>
                    <!-- /.post-wrapper -->
                </div>
                <!-- /.col-md-4 -->

            </div>
            <!-- /#blogGrid -->

        </div>
        <!-- /.row -->

    </div>
    <!-- /.container -->
</section>