<div class="row">

    <div class="col-md-8 col-md-offset-2">
        <br>
        <h1>Agregar Periodico</h1>
        <br>
        <section>
            <!-- add #resetMe // we will use it to reference this input field -->
            <input #resetMe type="file" name="file" id="file" (change)="uploadNewsImage($event)">
            <!-- add the "|| !imageURL" to the hidden condition here too as we can't easily reset an observable -->

            <a [href]="downloadURL | async" [hidden]="!downloadURL || !logoURL"> click this link {{ downloadURL | async }}</a>

            <div class="materialboxed" width="250" [hidden]="!logoURL">
                <img [src]="logoURL || '//:0' ">
            </div>


        </section>

        <form class="col s12" [formGroup]="newsForm" (ngSubmit)="saveNewsPaper()">
            <!-- Upload Buttom -->

            <div class="form-group">
                <label for="name">Nombre <span class="require">*</span></label>
                <input type="text" class="form-control" formControlName="name" />
            </div>

            <div class="form-group">
                <label for="url">URL</label>
                <input type="text" class="form-control" formControlName="url" />
            </div>

            <div class="form-group">
                <p><span class="require">*</span> - required fields</p>
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-primary">
              Guardar
           </button>

            </div>
        </form>
    </div>

</div>
