<section class="section-padding banner-wrapper login-alt banner-6 fullscreen-banner">
    <div class="container">

        <div class="login-wrapper">
            <div class="card-wrapper"></div>
            <div class="card-wrapper">

                <div class="desc">
                    <h2>Olvid&oacute; su Contrase&ntilde;a</h2>
                    <p>Le podemos enviar un link para restablecerla.</p>
                </div>

                <div class="input-container">
                    <input type="text" [(ngModel)]="email" id="email" required="required" />
                    <label for="email">Email</label>

                    <div class="bar"></div>

                </div>

                <div class="button-container">
                    <!-- <a href="#." class="btn btn-lg btn-block waves-effect waves-light">Login</a> -->
                    <button (click)="resetPassword(email)" class="btn btn-lg btn-block waves-effect waves-light">Acceder</button>
                </div>
                <div class="footer"><a routerLink="/reset-password">Olvido su Contrase&ntilde;a?</a></div>

            </div>

        </div>

    </div>
</section>
