import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AngularFireStorage } from '@angular/fire/storage';

import { FormBuilder, FormControl, FormGroup, Validators  } from '@angular/forms';
import { LiveService } from '../live.service';
import { Live } from '../live.model';



@Component({
  selector: 'app-live-add',
  templateUrl: './live-add.component.html',
  styleUrls: ['./live-add.component.css']
})
export class LiveAddComponent implements OnInit {
inputField: any;

liveForm: FormGroup;
downloadURL: Observable<string>;
liveImgURL: string;



  constructor( private liveService: LiveService,
               private storage: AngularFireStorage,
               private fb: FormBuilder) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    this.liveForm = this.fb.group({
      name : ['', [Validators.required, Validators.minLength(3)]],
      desc : ['', [Validators.required, Validators.minLength(5)]],
      liveUrl : ['', [Validators.required, Validators.pattern(urlRegex)]]
    });
  }

  saveLive(){
    const formData: Live = {
    name: this.liveForm.get('name').value,
    desc: this.liveForm.get('desc').value,
    liveUrl: this.liveForm.get('liveUrl').value,
    liveImgURL : this.liveImgURL || null

    };
    if (!this.liveForm.untouched) {
      this.liveService.create(formData);
      this.liveForm.reset();
      this.liveImgURL = '';
    }
  }

  async uploadLiveImage(event){
    const file = event.target.files[0];
    const path = `lives/${file.name}`;

    if (file.type.split('/')[0] !== 'image'){
      return alert('Solo archivos de Imagenes');
    }else {
      const task = await this.storage.upload(path, file);
      const ref = this.storage.ref(path);

      this.downloadURL = ref.getDownloadURL();
      this.downloadURL.subscribe(url =>
        this.liveImgURL = url );
        }
  }



}
