import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';

// const routes: Routes = [

//   { path: 'signin', component: SigninComponent},
//   { path: 'signup', component: SignupComponent},
// ];

@NgModule({
  declarations: [],
  imports: [RouterModule
  ],
  exports: [RouterModule]
})



export class AuthRoutingModule { }
