<!-- <main>
  <div class="chat-feed" #scroller>
    <app-chat-feed></app-chat-feed>
  </div>
  <div class="chat-input">
    <hr>
    <app-chat-input></app-chat-input>
  </div>
</main> -->


<div class="chat-area">
    <div class="chats">

        <app-chat-feed></app-chat-feed>


    </div>




</div>
<!--/ Chat content area -->

<!-- Chat footer <-->
<div class="chat-footer">
    <!-- <form onsubmit="enter_chat();" action="javascript:void(0);" class="chat-input">
    <i class="material-icons mr-2">face</i>
    <i class="material-icons mr-2">attachment</i>
    <input type="text" placeholder="Type message here.." class="message mb-0">
    <a class="btn waves-effect waves-light send" onclick="enter_chat();">Send</a>
  </form> -->

    <app-chat-input></app-chat-input>


</div>
<!--/ Chat footer -->
