<div class="container">
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <div class="b-blog-single-content wow slideInLeft">
                <div class="blog-item-content clearfix">
                    <div class="blog-item-caption">
                        <h4 class="item-name">
                            Politica de Privacidad
                        </h4>


                        <h2>Privacy Policy</h2>
                        <p>Your privacy is important to our application and always has been. So we’ve developed a Privacy Policy that covers how we collect, use, disclose, transfer, and store your information. Please take a moment to familiarize yourself
                            with our privacy practices. We collect information from you in order to provide corresponding service and better user experience.</p>

                        <h2>Information Collection and Use</h2>
                        <ul>
                            <li>Generally. We may collect personal information from our users in order to provide you with a personalized, useful and efficient experience. The categories of information we collect can include: Social network's personal information
                                and device's gallery pictures.
                            </li>
                            <li>Non-personal identification. We may collect non-personal identification information about installed applications, application usage information and device information. The information you give us, for example, when you give
                                us your opinions to our application and services via our feedback channel, such as your email address, and names.
                            </li>
                        </ul>
                        <h2>EU USER CONSENT POLICY</h2>
                        <p>We include certain disclosures to users in the European Economic Area (EEA) & obtain their consent to use cookies or other local storage, where legally required, and to use personal data (such as AdID) to serve ads. This policy
                            reflects the requirements of the EU ePrivacy Directive and the General Data Protection Regulation (GDPR).</p>
                        <p>We ask about permission of users which are in <b>EEA(European Economic Area)</b> to display ads like <b>PERSONALIZE/NON-PERSONALIZE</b>.</p>


                        <h2>PRIVACY POLICY OF THIRD PARTY SERVICE PROVIDERS USED BY THE APP</h2>
                        <p>For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information. The information that I request is retained on your device and is not collected by me in any way</p>

                        <h2>HOW WE USE COLLECTED INFORMATION</h2>
                        <p>We may use your information only for serving you better as below:</p>
                        <ul>
                            <li>To personalize user experience</li>
                            <li>To help develop our service- We may use Non- Personal Information to provide, maintain, improve and develop our services;
                            </li>
                            <li>We may collect your non-personal information and use them for market and promotion purpose, for example, we may promote or recommend more relevant apps to you.
                            </li>
                        </ul>
                        <p>We use this information to operate, maintain, and provide to you the features and functionality of our Apps, as well as to communicate directly with you, such as to send you email messages and push notifications. We may also send
                            you Service-related emails or messages (e.g., account verification, order confirmations, change or updates to features of our apps, technical and security notices).</p>
                        <h2>How your information may be disclosed?</h2>
                        <p>We may use your information only for serving you better as below:</p>
                        <ul>
                            <li>A. Personal Information. In general, we use Session and Usage Data internally to serve our Users and enable them to take maximum advantage of the Applications, the Services and the Site. We do not store Personal Information
                                and therefore we do not disclose your Personal Information.
                            </li>
                            <li>B. Non-Personal Information. We may disclose Non-Personal Information to our trusted partners who shall comply with this privacy policy and the relevant privacy laws. We do not combine Non-Personal Information with Personal
                                Information (such as combining your name with your unique User Device number).
                            </li>

                        </ul>


                        <h2>Third-Party Sites and Services</h2>
                        <p>Our application and services may contain links to third-party websites, products, and services. Our products and services may also use or offer products or services from third parties. Data collected by third party parties, which
                            may include such things as location data or contact details, is governed by their privacy practices. We encourage you to learn about the privacy practices of those third parties. We are not responsible for, the privacy practices
                            of Websites operated by third parties, whether they are linked to or otherwise accessible from application. The inclusion of a link or accessibility of third party Websites does not imply endorsement of such third party Website
                            by us.</p>

                        <h2>THIRD- PARTY SITES</h2>
                        <p>In general, the Applications, the Services and the Site access third party information (such as your Facebook account information) through application interfaces. We may provide links to third-party Web sites, such as Facebook,
                            as a service to our Users. The Site may also carry advertisements from other companies. When you click on links of third parties in an Application, the Services or the Site, you may leave the Application, the Services or the
                            Site. Some of these third party sites may be co-branded with our name/logo or our affiliated entity's name/logo, even though they are not operated or maintained by us. This Policy does not address, and we are not responsible
                            for, the privacy practices of Web sites operated by third parties, whether they are linked to or otherwise accessible from an Application, the Services or the Site. The inclusion of a link or accessibility of third party Websites
                            does not imply endorsement of such third party Website by us
                        </p>
                        <p>The data that you transfer and share through application may be intercepted, collected, used and disclosed by third parties. We are not responsible for any interception, collection, use and disclosure of your information by any
                            third party.
                        </p>

                        <h2>Will This Privacy Policy Ever Change?</h2>
                        <p>As our Apps evolves, we may need to update this Policy to keep pace with changes in the Service, our business and laws applicable to us and you; we will, however, always maintain our commitment to respect your privacy. We will
                            post any revisions to this Policy, along with their effective date, in an easy to find area of our website, so we recommend that you periodically check back here to stay informed of any changes. As long as you continue to use
                            the Service, you are bound by the terms of the Privacy Policy. If you disagree with any changes to this Policy and do not wish your information to be subject to a revised Policy, you will need to close your account and/or stop
                            using the Service.</p>
                        <p>For material changes that impact the collection, use, disclosure or retention of personal information, or for other changes where obtaining your prior consent may be required by applicable law, we will provide notice by sending
                            you an email at the address we have on file for you prior to any changes.</p>

                        <h2>Advertising and promotion</h2>
                        <p>What adverts you may see as a result of playing our application or using our Services. We promote our own applications and services in a number of ways. That might include cross promoting one of our applications while you are playing
                            a different application of ours. It might also include advertising our applications within websites and other media published by others, or sending you marketing materials by email.</p>

                        <h2>How secure is My Data?</h2>
                        <p>We take the security of your data very seriously. We do not collect Personal Information, and we employ administrative, physical and electronic measures designed to protect your Non-Personal Information from unauthorized access
                            and use. Please be aware that no security measures that we take to protect your data are absolutely guaranteed to avoid unauthorized access or use of your data.</p>
                        <p>When you use some our applications or services, the data and content you share is visible to other users and can be read, collected or used by them. You are responsible for the information you choose to share or submit in these
                            instances.
                        </p>

                        <h2>Deletion Information</h2>
                        <p>When information is no longer needed, we shall delete it using reasonable measures to protect the information from unauthorized access or use.</p>

                        <h2>Sensitive Information</h2>
                        <p>We ask that you not send us, and you not to disclose, any sensitive Personal Information (e.g., information related to racial or ethnic origin, political opinions, religion or other beliefs, health, sexual orientation, criminal
                            background or membership in past organizations, including trade union memberships) on or through an Application, the Services or the Site or otherwise to us.</p>

                        <h2>Contacting Us</h2>
                        <p>If you have any questions or queries about this policy or out privacy practices, or to report any violations of this policy of our applications, please contact us at lagrasadigital@gmail.com
                        </p>
                    </div>
                    <!-- bxslider -->

                    <!-- END bxslider -->
                    <div class="b-text wow fadeInUp">
                        <!--
                      <p>
                         Asunción indicó que las firmas de auditores externos, pueden contribuir significativamente a mejorar la calidad de las operaciones, la posición financiera y en consecuencia, la estabilidad financiera de las mismas, en la medida en que produzcan resultados de calidad, coadyuvando de esta manera a promover la confianza del mercado en los estados y resultados de las Entidades de Intermediación Financiera y Cambiaria, lo que constituye a su vez, un factor clave para una supervisión bancaria eficaz, por el aporte de información valiosa y de calidad al proceso de supervisión.
                          <br>
                          Destacó que la Superintendencia de Bancos ha implementado un programa de revisiones de calidad a las auditorías externas realizadas por las firmas de auditores que prestan servicios en las entidades de intermediación financiera, con el objetivo de promover la mejora en la calidad de las mismas y potenciar la efectividad de la supervisión prudencial, contribuyendo así, de igual manera, con la estabilidad financiera del sistema.
                      </p>
-->
                    </div>
                    <!-- TWO COLUMNS WITH TEXT  -->
                    <div class="b-text-2-columns">

                    </div>
                    <!-- END TWO COLUMNS WITH TEXT -->
                    <div class="b-text wow fadeInUp">

                    </div>
                    <div class="b-single-tags-share clearfix wow fadeInUp">
                        <div class="b-tags pull-left">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
