<app-header></app-header>
<section class="full-width promo-box brand-bg ptb-50">
    <div>
        <div class="col-md-12">
            <div class="promo-info">

                <h2 class="white-text text-bold text-uppercase no-margin">Dale a play, disfruta !!! </h2>
            </div>
            <div class="promo-btn">
                <audio controls autoplay>

							<source src="https://s3.radio.co/sfa96e3fea/listen" type="audio/mpeg"> Your browser does not support the audio
							element.
            </audio>
                <!--Reproductor de Audio-->
            </div>
        </div>
    </div>
</section>

<router-outlet></router-outlet>


<app-footer></app-footer>
